import store from "@/store";
import cloneDeep from "lodash/cloneDeep";

export const commonValidationRules = {
  required: (v) =>
    (v !== "" && v !== undefined && v !== null) || "Заполните поле",
  email: (v) => v === "" || /.+@.+\..+/.test(v) || "Введите корректный E-mail",
  [`phone-number`]: (v) =>
    v === "" ||
    /^\+?(\d{1,3})?[- .]?\(?\d{2,3}\)?[- .]?\d\d\d[- .]?\d\d\d\d$/.test(v) ||
    "Введите корректный номер телефона",
  url: (v) =>
    v === "" ||
    /[-a-zA-Z0-9@:%_+.~#?&/=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&/=]*)?/gi.test(
      v
    ) ||
    "Введите корректный URL",
  latin: (v) =>
    v === "" ||
    /^[a-zA-Z-_0-9]+$/i.test(v) ||
    "Ввод доступен только на латинице",
  cannotStartWithNumber: (v) =>
    v === "" ||
    /^[^0-9]/i.test(v) ||
    "Начало строки не может начинаться с числа",
  fizInn: (v) =>
    v === "" ||
    (v.length === 12 && /^[0-9]{12}$/.test(v)) ||
    "Введите корректный ИНН",
  yurInn: (v) =>
    v === "" ||
    (v.length === 10 && /^[0-9]{10}$/.test(v)) ||
    "Введите корректный ИНН",
  inn: (v) =>
    v === "" || /^[0-9]{12}$|^[0-9]{10}$/.test(v) || "Введите корректный ИНН (10 или 12 цифр)",
  phoneNumber: (v) =>
    v === "" ||
    (v.length <= 18 &&
      /^\+\d\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}$/.test(v)) ||
    "Введите корректный номер телефона",
};

export function generateId() {
  return Date.now() + Math.floor(Math.random() * 100);
}

export function formatPrimitiveChar(char) {
  return char === "Y";
}

/**
 * Максимальный размер файлов в байтах (100мб)
 * @type {number}
 */
export const maxFileSize = 104857600;

export function convertMb(size) {
  const oneMb = 1048576;
  if (size <= oneMb) {
    return "1Mb";
  }
  return Math.floor(size / oneMb) + "Mb";
}

export function replaceRole(id) {
  const groups = store.getters["group/group"];
  const role = groups?.find((group) => group.id === id);
  return role ? role.name : "";
}

export function prepareFields(inputs) {
  const cloneInputs = cloneDeep(inputs);
  return cloneInputs
    .map((field) => {
      if (field.type === "group") {
        return;
      }
      if (field.group_id === 0 || field.group_id === "") {
        return field;
      }
      const groupName = cloneInputs.find(
        (group) => group.id === field.group_id
      ).name;
      const foundGroups = cloneInputs.filter(
        (group) => group.name === groupName
      );
      const index = foundGroups.findIndex(
        (group) => group.id === field.group_id
      );
      field.name = field.name.replace("[]", `[n${index}]`);
      return field;
    })
    .filter(Boolean);
}

export function findItem(findType, findValue, items) {
  return items.find((item) => item[findType] === findValue);
}

export const defaultStatus = {
  accept: "Анкета одобрена",
  reject: "Анкета отклонена",
  revision: "Требует доработки",
  new: "На рассмотрении",
  draft: "Черновик",
  sent: "Повторная проверка",
};

export function formatDate(inputDate) {
  const date = new Date(inputDate);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
