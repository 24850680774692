<template>
  <v-card
    elevation="0"
    class="item"
  >
    <div class="item__content content">
      <div class="content__container">
        <div class="content__logo">
          <div class="position-relative">
            <div
              v-if="false"
              class="d-flex align-center justify-center"
            >
              <v-progress-circular
                color="primary"
                :indeterminate="true"
              />
            </div>
            <div
              v-else
              class="logo"
            >
              <img
                :src="item.logo"
                :alt="altImage"
                class="logo__img"
              >
            </div>
          </div>
        </div>
        <h3 class="content__title">
          {{ item.name }}
        </h3>
        <p class="content__description">
          {{ item.preview }}
        </p>
        <p
          class="content__info"
        >
          <v-btn
            v-if="item.description_link"
            variant="text"
            size="small"
            color="primary"
            :href="item.description_link"
            target="_blank"
            class="px-0 pt-1"
          >
            {{ meta?.isSelections ? 'Заполнить анкету' : 'Подать заявку на конкурс' }}
          </v-btn>
          <v-btn
            v-else
            variant="text"
            size="small"
            color="primary"
            class="px-0 pt-1"
            @click.prevent="onItem(item.id)"
          >
            {{ meta?.isSelections ? 'Заполнить анкету' : 'Подать заявку на конкурс' }}
          </v-btn>
        </p>
      </div>
      <div
        v-if="Boolean(item.new)"
        class="content__special-tag"
      >
        <span>новый {{ typeTitle }}</span>
      </div>
    </div>
  </v-card>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent( {
  name: "ServiceItem"
});
</script>
<script setup>
import { useRouter } from "vue-router";
import {computed} from "vue";
import {useRoute} from "vue-router/dist/vue-router";

const route = useRoute()
const {meta} = route

const props = defineProps({
  item: {
    type: Object,
    default: () => {
      return {}
    }
  },
});

const router = useRouter()

const altImage = computed(() => {
  const { logo } = props.item
  return logo?.split('/')?.pop() ?? ''
})
const typeTitle = computed(() => meta?.isSelections ? 'отбор' : 'конкурс')

function onItem(id) {
  const routeParams = meta?.isSelections ? '/selections' : '/service'
  router.push(`${routeParams}/${id}`)
}
</script>

<style lang="scss" scoped>
.text {
  &__container {
    margin-bottom: 32px;
  }

  &__title {
    color: #000000;
    font-size: 40px;
    font-weight: normal;
    line-height: 55px;
    letter-spacing: 0;
  }

  &__description {
    color: #000000;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
  }

}

.item {
  height: 100%;
  transition: .2s;

  &:hover {
    transform: translateY(-2px);
  }
}

.content {
  height: 100%;
  display: flex;
  padding: 40px 20px 55px 40px;
  position: relative;
  background: #fff;
  box-shadow: 0 5px 15px rgb(0 0 0 / 5%);
  min-height: 270px;
  border-radius: 0;
  flex-direction: column;
  justify-content: space-between;

  &__special-tag {
    top: 20px;
    right: 20px;
    height: 24px;
    display: flex;
    position: absolute;
    background: #1A2574;
    align-items: center;
    padding-left: 8px;
    border-radius: 2px;
    padding-right: 8px;
    text-transform: uppercase;

    & > span {
      color: #ffffff;
      font-size: 12px;
      line-height: 1;
      letter-spacing: .05em;
    }
  }

  &__logo {
    height: 60px;
    display: inline-block;
    min-width: 60px;

    & > div {
      height: 60px;
    }
  }

  &__title {
    margin-top: 20px;
    margin-bottom: 5px;
    color: #000000;
    font-size: 18px;
    font-weight: normal;
    line-height: 27px;
    letter-spacing: 0;
  }

  &__description {
    font-size: 16px;
    line-height: 22px;
  }

  &__info {
    color: #F3A23F;
    bottom: 26px;
    position: absolute;
  }
}

.logo {
  transition: opacity .25s;
  min-height: 95px;
  &__img {
    height: 60px;
    max-width: 100%;
    object-fit: contain;
  }
}
</style>
