<template>
  <the-container>
    <template #title>
      Анкета № {{ questionnaire.id }}
    </template>
    <div class="px-0">
      <v-row>
        <v-col
          md="3"
          sm="12"
        >
          <span class="text-body-1">Наименование анкеты</span>
        </v-col>
        <v-col
          md="3"
          sm="12"
        >
          {{ questionnaire?.forma?.title }}
        </v-col>
      </v-row>
      <v-row v-if="questionnaire?.date_submit">
        <v-col
          md="3"
          sm="12"
        >
          <span class="text-body-1">Дата и время подачи</span>
        </v-col>
        <v-col
          md="3"
          sm="12"
        >
          {{ questionnaire?.date_submit }}
        </v-col>
      </v-row>
      <v-row>
        <v-col
          md="3"
          sm="12"
        >
          <span class="text-body-1">Название конкурса</span>
        </v-col>
        <v-col
          md="3"
          sm="12"
        >
          {{ questionnaire?.contest?.name }}
        </v-col>
      </v-row>
      <v-row>
        <v-col
          md="3"
          sm="12"
        >
          <span class="text-body-1">Статус</span>
        </v-col>
        <v-col
          md="3"
          sm="12"
        >
          {{ defaultStatus[questionnaire?.status] }}
        </v-col>
      </v-row>
      <v-row v-if="isShowReason">
        <v-col
          md="3"
          sm="12"
        >
          <span
            class="text-body-1"
            style="color: red"
          >Причина</span>
        </v-col>
        <v-col
          md="3"
          sm="12"
          style="color: red"
        >
          {{ questionnaire?.reason }}
        </v-col>
      </v-row>
      <v-row class="py-4 mb-2">
        <v-col>
          <v-divider />
        </v-col>
      </v-row>
      <FormMain
        v-if="canEdit"
        ref="formRef"
        :key="keyForm"
        :data="fieldsForm"
        :can-no-validate="noValidate"
        :comments="collectionOfComments"
        @get-forms="sendForm"
      />
      <template v-if="!canEdit">
        <v-row class="d-none d-md-flex">
          <v-col md="3">
            <span class="text-body-1 font-weight-bold">Наименование</span>
          </v-col>
          <v-col :md="questionnaire?.status === 'accept' ? '8' : '4'">
            <span class="text-body-1 font-weight-bold">Значение</span>
          </v-col>
          <v-col v-if="questionnaire?.status !== 'accept'">
            <span class="text-body-1 font-weight-bold">Комментарий</span>
          </v-col>
        </v-row>
        <div
          v-for="value in getSortFields()"
          :key="value.id"
        >
          <template v-if="!value.group_id && value.type !== 'group'">
            <v-row class="flex-column flex-md-row mt-4">
              <v-col md="3">
                <span class="text-body-1">{{ value.title }}</span>
              </v-col>
              <v-col
                :md="questionnaire?.status === 'accept' ? '8' : '4'"
                class="text-break"
              >
                {{ value.default_value || value.fileUrl || "" }}
              </v-col>
              <v-col v-if="canComment">
                <v-textarea
                  :model-value="collectionOfComments[value.id]"
                  density="compact"
                  variant="outlined"
                  rows="2"
                  hide-details="auto"
                  @update:model-value="setValue($event, value.id)"
                />
              </v-col>
              <v-col v-if="!canComment">
                {{ collectionOfComments[value.id] }}
              </v-col>
            </v-row>
          </template>
          <!-- ------------------------------------------ IF GROUP ------------------------------------------ -->
          <div
            v-else-if="value.type === 'group'"
            class="mb-5 mt-5"
          >
            <v-expansion-panels
              :model-value="value.index ?? field.group_id"
              expandable="true"
              multiple
              class="mt-4"
            >
              <v-expansion-panel>
                <v-expansion-panel-title>
                  <v-row
                    align="center"
                    class="flex-sm-row flex-column"
                  >
                    {{ value.title }}
                  </v-row>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  <div class="mb-5 mt-5">
                    <v-row
                      v-for="field in getGroupValues(value.id)"
                      :key="field?.id"
                      class="flex-column flex-md-row"
                    >
                      <v-col md="3">
                        <span class="text-body-1">{{ field?.title }}, {{ fields?.id }}</span>
                      </v-col>
                      <v-col
                        md="4"
                        class="text-break"
                      >
                        {{ field?.default_value || field?.fileUrl || "" }}
                      </v-col>
                      <v-col v-if="canComment">
                        <v-textarea
                          :model-value="collectionOfComments[field?.id]"
                          density="compact"
                          variant="outlined"
                          rows="2"
                          hide-details="auto"
                          @update:model-value="setValue($event, field?.id)"
                        />
                      </v-col>
                      <v-col v-if="!canComment">
                        {{ collectionOfComments[field?.id] }}
                      </v-col>
                    </v-row>
                  </div>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- ------------------------------------------------------------------------------------------------------------ -->
          </div>
        </div>
      </template>
      <v-row
        v-if="canComment"
        justify="center"
        class="mt-4"
      >
        <v-col cols="auto">
          <v-btn
            color="primary"
            width="150"
            @click.prevent="sendStatus(ACCEPT_STATUS)"
          >
            Принять
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            color="primary"
            width="150"
            @click.prevent="openDialog(REJECT_STATUS)"
          >
            Отклонить
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            color="primary"
            width="150"
            @click.prevent="openDialog(REVISION_STATUS)"
          >
            На доработку
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="canEdit">
        <v-col cols="auto">
          <v-btn
            color="primary"
            @click="getForm(null)"
          >
            Отправить на рассмотрение
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            color="primary"
            @click="getForm(DRAFT_STATUS)"
          >
            Сохранить черновик
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <template
      v-if="
        (isManager || isExpert) &&
          questionnaire.status !== 'draft' &&
          questionnaire.status !== 'revision'
      "
    >
      <div class="font-weight-bold mt-12">
        Выгрузить анкету
      </div>
      <v-col class="ma-0 pa-0 mt-5 flex-wrap">
        <template v-if="fieldsList.length">
          <div class="mt-6">
            Список полей:
          </div>
          <v-combobox
            v-model="selectedFields"
            :items="fieldsList"
            return-object
            item-title="title"
            item-value="name"
            variant="outlined"
            hide-details="auto"
            multiple
          />
        </template>
        <div class="mt-6">
          Тип выгрузки:
        </div>
        <v-select
          v-model="selectedTypeExport"
          :items="typesExport"
          return-object
          item-title="title"
          item-value="id"
          variant="outlined"
          hide-details="auto"
        />
        <v-btn
          color="primary"
          variant="text"
          class="my-6 px-0"
          @click="exportResult"
        >
          Выгрузить в .xls
        </v-btn>
      </v-col>
    </template>
    <confirm-dialog
      ref="agreementConfirmationDialog"
      @confirm="setUserAgreement"
    >
      <v-row class="pa-0 ma-0 text-center justify-center">
        Нажимая кнопку "ОТПРАВИТЬ" Вы даете &nbsp;
        <span
          class="text-blue-darken-4 cursor-pointer"
          @click="() => openFile(contest?.file_agreement, true)"
        >
          Согласие на обработку персональных данных
        </span>
        &nbsp; в соответствии с &nbsp;
        <span
          class="text-blue-darken-4 cursor-pointer"
          @click="() => openFile('/Политика конфиденциальности.pdf')"
        >
          Политикой конфиденциальности
        </span>
        &nbsp; и условиями &nbsp;
        <span
          class="text-blue-darken-4 cursor-pointer"
          @click="() => openFile('/Пользовательское соглашение.pdf')"
        >
          Пользовательского соглашения
        </span>
      </v-row>
    </confirm-dialog>
  </the-container>

  <v-dialog v-model="isShowDialog">
    <v-card>
      <v-card-text>
        Установить анкете статус - "{{ defaultStatus[selectedStatus.status] }}"
      </v-card-text>
      <v-card-text> Добавьте комментарий </v-card-text>
      <v-card-text>
        <v-textarea
          v-model="modalTextPrivate"
          rows="5"
          color="primary"
          variant="outlined"
          class="text-pre-wrap"
        />
      </v-card-text>
      <v-card-actions>
        <v-row
          :no-gutters="true"
          justify="center"
        >
          <v-col cols="auto">
            <v-btn
              :disabled="!canSendStatus"
              @click="setCurrentStatus"
            >
              Отправить
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn @click="closeDialog">
              Отменить
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "QuestionnaireView",
};
</script>

<script setup>
import TheContainer from "@/components/TheContainer";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from "vue";
import { api } from "@/api/Api";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "@/composables/useToast";
import { useStore } from "vuex";
import {
  ACCEPT_STATUS,
  DRAFT_STATUS,
  NEW_STATUS,
  REJECT_STATUS,
  REVISION_STATUS,
  SENT_STATUS,
} from "@/utils/constants";

import { useForms } from "@/composables/forms/useForms";
import FormMain from "@/components/Form/FormMain";
import { defaultStatus } from "@/utils/common";
import { sendYandexMetrics, sendTopMailRuMetrics } from "@/metrics";

const route = useRoute();
const router = useRouter();
const { toast } = useToast();
const store = useStore();
const { getValues } = useForms();

const questionnaire = ref([]);
const collectionOfComments = ref({});
const comments = ref([]);
const fieldsForm = ref([]);
const keyForm = ref(1);
const formRef = ref(null);
const nextStatus = ref(null);
const noValidate = ref(false);
const agreementConfirmationDialog = ref(null);
const fieldsList = ref([]);
const selectedFields = ref();
const selectedStatus = ref({});
const modalTextPrivate = ref(null);

const isShowDialog = ref(false);
function openDialog(status) {
  selectedStatus.value = { status };
  isShowDialog.value = true;
}
function closeDialog() {
  isShowDialog.value = false;
  selectedStatus.value = {};
  modalTextPrivate.value = "";
}
function setCurrentStatus() {
  sendStatus(selectedStatus.value.status);
}

// Check if environment is production, then send Yandex and TopMailRu metrics
if (
  process.env.NODE_ENV === "production" &&
  nextStatus.value !== DRAFT_STATUS
) {
  onMounted(() => {
    const goal = questionnaire.value?.contest?.metrika_goal || "anketa"; // Define your Yandex Metrica goal here
    sendYandexMetrics(goal);
    sendTopMailRuMetrics(goal);
  });
}

const typesExport = ref([
  {
    id: 0,
    title: "Горизонтальная",
  },
  {
    id: 1,
    title: "Вертикальная",
  },
]);

const hasReason = computed(() => {
  return (
    questionnaire.value.reason &&
    questionnaire.value.reason !== "undefined" &&
    questionnaire.value.reason !== "" &&
    questionnaire.value.reason !== null
  );
});

const hasEmptyReason = computed(() => {
  return (
    questionnaire.value.reason !== "undefined" ||
    questionnaire.value.reason !== "" ||
    questionnaire.value.reason !== null
  );
});

const selectedTypeExport = ref(typesExport.value[1]);
const agreement = ref(true);
const contest = computed(() => store.getters["contest/contest"]);
const questionnaireId = computed(() => route.params?.id ?? null);
const recipientId = computed(() => questionnaire.value?.user?.id);
const isManager = computed(() => store.getters["user/isManager"]);
const isExpert = computed(() => store.getters["user/isExpert"]);
const canSendStatus = computed(() => {
  const hasComments = Object.values(collectionOfComments.value).some(Boolean);
  const hasModalText = modalTextPrivate.value;

  if (hasEmptyReason.value && hasComments) {
    return true;
  } else if (hasEmptyReason.value && hasModalText) {
    return true;
  } else {
    return false;
  }
});

const isUser = computed(() => store.getters["user/isUser"]);

const canComment = computed(() => {
  if (!isManager.value) {
    return false;
  }

  const statuses = [NEW_STATUS, SENT_STATUS];
  return statuses.includes(questionnaire.value?.status);
});
const isShowReason = computed(() => {
  const isRevisionOrReject =
    questionnaire.value.status === "revision" ||
    questionnaire.value.status === "reject";
  const isUserFilled = isUser.value;

  if (hasReason.value && isUserFilled) {
    return isRevisionOrReject;
  } else {
    return hasReason.value;
  }
});

const canEdit = computed(() => {
  if (isManager.value || isExpert.value) {
    return false;
  }

  const statuses = [DRAFT_STATUS, REVISION_STATUS];
  return statuses.includes(questionnaire.value?.status);
});

function getSortFields() {
  return fieldsForm?.value?.sort((x, y) => x.sort - y.sort);
}

async function openFile(fileLink, isBlobFile) {
  if (isBlobFile) {
    if (!fileLink) {
      return;
    }
    await api
      .upload()
      .getFile(fileLink)
      .then((response) => {
        const fileData = URL.createObjectURL(response);
        fileLink = fileData;
        window.open(fileLink, "_blank").focus();
      });
  } else {
    window.open(fileLink, "_blank").focus();
  }
}

async function exportResult() {
  try {
    const form = new FormData();
    let fieldsNames = selectedFields.value?.map((el) => el.name);

    questionnaire.value?.forma?.fields?.forEach((el) => {
      if (el.type === "group") {
        const id = el.id;
        const findGroup = selectedFields.value?.filter(
          (el) => el.group_id === id
        );

        if (findGroup?.length) {
          const isHasField = fieldsNames.find((field) => field === el.name);
          if (!isHasField) {
            fieldsNames.push(el.name);
          }
        }
      }
    });

    selectedFields.value?.forEach((el) => {
      if (el.type === "group") {
        const filterFieldsGroup = questionnaire.value?.forma?.fields?.filter(
          (field) => field.group_id === el.id
        );
        filterFieldsGroup?.forEach((fieldField) => {
          const isHasInArray = fieldsNames.find(
            (item) => item === fieldField.name
          );
          if (!isHasInArray) {
            fieldsNames.push(fieldField.name);
          }
        });
      }
    });

    fieldsNames?.forEach((el) => {
      form.append("fields[]", el);
    });
    form.append("id", questionnaire.value?.forma.id);
    form.append("direction", selectedTypeExport.value.id);
    form.append("results[]", questionnaireId.value);

    await api
      .result()
      .exportResultByFormId(form)
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const filename = questionnaire.value?.forma.title;
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Не удалось сформировать файл");
      });
  } catch (err) {
    console.log(err);
  }
}

const localStorageKey = `questionnaireReview${questionnaireId.value}`;

function setValue(value, id) {
  collectionOfComments.value[id] = value;
  localStorage.setItem(
    localStorageKey,
    JSON.stringify(collectionOfComments.value)
  );
}

async function setUserAgreement() {
  try {
    const params = {
      contest_id: questionnaire.value?.contest?.id,
    };

    await api
      .contest()
      .setUserAgreement(params)
      .then(() => {
        loadData();
      });
  } catch (err) {
    console.log(err);
  }
}

function setFields(fields) {
  return (fieldsForm.value = fields);
}

function getGroupValues(id) {
  return fieldsForm.value?.filter((el) => el.group_id === id);
}

async function getMessages(values) {
  try {
    const params = new URLSearchParams({
      context: "contest_form_results_values",
    });
    params.append("parent_object", route.params?.id || "");
    params.append("pageSize", 100);
    params.append("project", process.env.VUE_APP_PROJECT_ID);
    const data = params.toString();

    await api
      .message()
      .getBatchMessages(data)
      .then((data) => {
        const { items } = data;
        let messagesList = items.reduce(
          (acc, message) => {
            if (acc.map[message.object]) {
              return acc;
            }
            acc.map[message.object] = true;
            acc.messages.push(message);
            return acc;
          },
          { map: {}, messages: [] }
        ).messages;

        messagesList.forEach((item, index) => {
          collectionOfComments.value[item.object] = item.message;
          comments.value[index] = item;
        });
      });
  } catch (err) {
    console.log(err);
  }
}

async function loadData() {
  questionnaire.value = await api.result().getResultById(questionnaireId.value);
  await store.dispatch("contest/setContest", questionnaire.value?.contest);
  agreement.value = questionnaire.value?.contest_status?.agreement === "Y";

  fieldsList.value = questionnaire.value?.forma.fields;
  selectedFields.value = fieldsList.value;
  const fields = questionnaire.value?.forma.fields;
  const values = questionnaire.value?.values;

  await getMessages(values);

  const newValue = getValues(
    questionnaire.value?.forma?.fields,
    questionnaire.value.values
  );
  fieldsForm.value = newValue;

  fields.value = newValue;
  setFields(newValue);
  fieldsForm.value = newValue;

  setTimeout(() => {
    keyForm.value += 1;
  }, 300);
}
onMounted(() => {
  loadData();
});

async function sendStatus(status) {
  if (status === REVISION_STATUS) {
    await sendMessage();
    localStorage.removeItem(localStorageKey);
  }

  await api
    .result()
    .setStatus(questionnaireId.value, status, modalTextPrivate.value)
    .then((message) => {
      toast.success(message);
    });
  modalTextPrivate.value = "";
  closeDialog();
  await router.push("/questionnaires");
}

function sendMessage() {
  const formData = new FormData();
  formData.append("project", process.env.VUE_APP_PROJECT_ID);
  formData.append("recipients[]", recipientId.value);
  formData.append("context", "contest_form_results_values");

  const deleteMessage = (messageId) => {
    const bodyDelete = new URLSearchParams({
      project: process.env.VUE_APP_PROJECT_ID,
    });

    try {
      api.message().deleteMessage(messageId, bodyDelete);
    } catch (err) {
      console.log(err);
    }
  };

  Object.entries(collectionOfComments.value).forEach(([key, value], index) => {
    const comment = comments.value?.find((el) => el?.object === +key);
    const newIndex = comment ? comment?.id : `index-${key}`;

    if (value === "") {
      deleteMessage(newIndex);
    }

    formData.append(`parent_object[${newIndex}]`, route.params?.id);
    formData.append(`object[${newIndex}]`, key);
    formData.append(`message[${newIndex}]`, value);
  });

  api.message().sendBatchMessages(formData);
}

function getForm(next = null) {
  noValidate.value = false;

  if (!formRef.value) {
    return;
  }
  const status = questionnaire.value.status;

  if (status === NEW_STATUS) {
    noValidate.value = false;
  }
  if (status === DRAFT_STATUS && !next) {
    noValidate.value = false;
    nextStatus.value = NEW_STATUS;
  }

  if ((status === DRAFT_STATUS || status === REVISION_STATUS) && next) {
    noValidate.value = true;
    nextStatus.value = DRAFT_STATUS;
  }

  if (status === REVISION_STATUS && !next) {
    nextStatus.value = SENT_STATUS;
  }

  nextTick(() => {
    formRef.value.getForm();
  });
}

function getSortGroupFields(fields) {
  const fieldsValue = fields.filter((el) => !!el.group_id);
  const map = fieldsValue.reduce((r, i) => {
    r[i.group_id] = r[i.group_id] || [];
    r[i.group_id].push(i);
    return r;
  }, {});

  const sortFields = [];
  for (const key in map) {
    sortFields.push(map[key]);
  }

  return sortFields;
}

function getSortMultipleFields(fields) {
  const map = fields.reduce((r, i) => {
    r[i.name] = r[i.name] || [];
    r[i.name].push(i);
    return r;
  }, {});

  const sortFields = [];
  for (const key in map) {
    sortFields.push(map[key]);
  }

  return sortFields;
}

function checkDuplicates(array, name) {
  const counts = {};
  // Подсчитываем количество вхождений каждого элемента
  array.forEach((item) => {
    counts[item] = (counts[item] || 0) + 1;
  });

  // Проверяем, если элемент встречается более одного раза
  if (counts[name] > 1) {
    return true;
  } else {
    return false;
  }
}

async function sendForm(inputs, valid) {
  if (!valid) {
    return;
  }
  const {
    contest: { id: contestId },
    forma: { id: formId },
  } = questionnaire.value;

  const formBody = new FormData();
  formBody.append("contest_id", contestId);
  formBody.append("form_id", formId);
  formBody.append("status", nextStatus.value);
  const sortFields = getSortGroupFields(inputs);
  const defaultFields = inputs.filter(
    (el) => !el.group_id && el.type !== "group"
  );

  const defaultFieldsNames = defaultFields.map((item) => item.name);

  let startIndexGroup = 10;

  sortFields?.forEach((items, indexGroup) => {
    let indexGroupValue = indexGroup
      ? startIndexGroup + indexGroup
      : startIndexGroup;

    const multipleSortValue = getSortMultipleFields(items);

    multipleSortValue.forEach((sortField) => {
      sortField.forEach((field, index) => {
        const indexField = index < startIndexGroup ? `0${index}` : `${index}`;

        const filedName = field.group_id
          ? `fields[${field.name}][${Number(
              `${indexGroupValue}${indexField}`
            )}]`
          : `fields[${field.name}]`;

        const filedValue =
          field.type === "file"
            ? field.default_value[0] || field.fileUrl || ""
            : field.default_value ?? "";
        formBody.append([filedName], filedValue);
      });
    });
  });

  defaultFields.forEach((field, index) => {
    const filedName = checkDuplicates(defaultFieldsNames, field.name)
      ? `fields[${field.name}][${index}]`
      : `fields[${field.name}]`;

    const filedValue =
      field.type === "file"
        ? field.default_value[0] || field.fileUrl || ""
        : field.default_value ?? "";

    formBody.append(filedName, filedValue);
  });

  try {
    await api.result().updateResult(questionnaireId.value, formBody);
    const message =
      nextStatus.value === DRAFT_STATUS
        ? "Черновик сохранен"
        : "Анкета отравлена на рассмотрение";
    toast.success(message);

    // if (nextStatus.value !== DRAFT_STATUS) {
    //   const goal = questionnaire.value?.contest?.metrika_goal || "anketa";
    //   sendYandexMetrics(goal);
    //   sendTopMailRuMetrics(goal);
    // }

    await loadData();
  } catch ({ response }) {
    const { data, status } = response;
    if (status === 500) {
      const message = "Возникла ошибка, попробуйте повторить позднее";
      toast.error(message);
    } else {
      data.forEach((msg) => {
        toast.error(msg.message);
      });
    }
  }
}

watch(questionnaire, () => {
  if (!agreement.value && isUser.value) {
    agreementConfirmationDialog.value.open();
  }
});

onUnmounted(() => {
  store.dispatch("contest/setContest", null);
});

onMounted(() => {
  collectionOfComments.value =
    JSON.parse(localStorage.getItem(localStorageKey)) ?? {};
  loadData();
});
</script>
