<template>
  <div
    v-if="descr"
    class="v-input__details"
  >
    <div class="v-messages">
      <div
        class="v-messages__message"
        v-html="descr"
      />
    </div>
  </div>
  <div
    v-for="(option, idx) in options"
    :key="option.value"
  >
    <v-checkbox
      v-model="select"
      :rules="rulesCommon"
      :label="option.name"
      :value="option.value"
      :disabled="formatPrimitiveChar(option.disabled)"
      color="primary"
      density="compact"
      @change="setNewValue"
    />
    <v-list-item
      v-if="option.description"
      :class="{
        'checkbox__description--last' : options.length - 1 === idx
      }"
      class="text-caption checkbox__description"
      v-html="option.description"
    />
  </div>
</template>

<script setup>
import {props as baseProps, emit as baseEmit} from "@/composables/form/usePropsForm";
import {useForm} from "@/composables/form/useForm";
import {onMounted, ref} from "vue";
import {formatPrimitiveChar} from "@/utils/common";

const props = defineProps({
  ...baseProps
});

const emit = defineEmits(baseEmit)

const select = ref([])

function setNewValue () {
  inputModel.value = select.value
}

const {rulesCommon, inputModel} = useForm(props, emit);

// onMounted(() => {
//   console.log('inputModel.value=', inputModel.value)
//   select.value = inputModel.value.split(',')
// })

onMounted(() => {
  if (typeof inputModel.value === 'string') {
    select.value = inputModel.value.split(',');
  } else {
    select.value = inputModel.value.value.split(',');
  }
});
</script>

<style lang="scss" scoped>
.checkbox{
  &__description{
    padding: 0;
    margin-top: -20px;
    margin-left: 40px;
    &--last{
      margin-bottom: 20px;
    }
  }
}
</style>
