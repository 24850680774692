<template>
  <div>
    <v-text-field
      v-if="!isPhone"
      :id="computedId"
      v-model="inputModel"
      :type="inputType"
      :hint="descr"
      :persistent-hint="Boolean(descr)"
      :maxlength="maxLength"
      :counter="maxLength"
      :rules="rulesCommon"
      color="primary"
      variant="outlined"
      class="text-pre-wrap"
    />
    <v-text-field
      v-if="isPhone"
      :id="`phoneNumber-${props.id}`"
      v-model="inputModel"
      variant="outlined"
      class="phoneInput"
      :class="{ 'error-input': !isPhoneNumberValid }"
      placeholder="+7 (9XX) XXX-XX-XX"
      autocomplete="tel"
      type="tel"
      :hint="descr"
      :persistent-hint="Boolean(descr)"
      :rules="rulesCommon"
      @input="maskPhoneNumber(`phoneNumber-${props.id}`)"
    />
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref } from "vue";
import { props as baseProps, emit as baseEmit } from "@/composables/form/usePropsForm";
import { useForm } from "@/composables/form/useForm";
import Inputmask from 'inputmask';

const props = defineProps(baseProps);
const emit = defineEmits(baseEmit);
const { maxLength, rulesCommon, inputModel } = useForm(props, emit);

const isInn = computed(() => Array.isArray(props.rules) && props.rules.some(item => Array.isArray(item) && (item.includes("inn") || item.includes("fizInn") || item.includes("yurInn"))));
const isPhone = computed(() => Array.isArray(props.rules) && props.rules.some(item => Array.isArray(item) && item.includes("phoneNumber")));

const computedId = `inputCustom${props.id}`;
const inputType = computed(() => (isInn.value || isPhone.value) ? 'numeric' : 'text');

const isPhoneNumberValid = ref(true);

const maskPhoneNumber = (fieldId) => {
  const phoneInput = document.getElementById(fieldId);
  if (phoneInput) {
    const phoneNumber = phoneInput.value.replace(/\D/g, '');
    isPhoneNumberValid.value = phoneNumber.length === 11;
    Inputmask({ mask: '+7 (999) 999-99-99' }).mask(phoneInput);
  }
};

</script>

<style lang="scss">
.phoneInput {
  height: 56px;
  width: 100%;
  margin-bottom: 16px;
}
.error-input {
  border-color: red;
}
</style>