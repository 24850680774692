<template>
  <auth-layout>
    <v-row
      align="center"
      justify="center"
      :no-gutters="true"
    >
      <v-progress-circular
        v-if="loading"
        :indeterminate="true"
        color="primary"
      />
    </v-row>
  </auth-layout>
</template>

<script setup>

import {useRoute} from "vue-router";
import {onMounted, ref} from "vue";
import AuthLayout from "@/layouts/auth";
import store from "@/store";

const route = useRoute()

const loading = ref(true)



async function auth() {
  const {code} = route.query
  if (!code) {
    return
  }

  loading.value = true
  const {success} = await store.dispatch('auth/login', code)
  loading.value = false
  if (!success) {
    return
  }
}

onMounted(auth)

</script>

