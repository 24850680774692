import { Auth } from "@/api/Auth";
import { Contest } from "@/api/Contest";
import { User } from "@/api/User";
import { Group } from "@/api/Group";
import { Result } from "@/api/Result";
import { Form } from "@/api/Form";
import {FormFields} from "@/api/FormFields";
import {Message} from "@/api/Message";
import {Upload} from "@/api/Upload";
import {Feedback} from "@/api/Feedback";
import {Notifications} from "@/api/Notifications";

class Api {
  auth () {
    return new Auth()
  }

  contest () {
    return new Contest()
  }

  user () {
    return new User()
  }

  group () {
    return new Group()
  }

  result () {
    return new Result()
  }

  form () {
    return new Form()
  }

  formFields () {
    return new FormFields()
  }

  message () {
    return new Message()
  }

  upload () {
    return new Upload()
  }

  feedback () {
    return new Feedback()
  }

  notifications () {
    return new Notifications()
  }
}

export const api = new Api()

