import { api } from "@/api/Api";

const state = {
  group: null
};

const getters = {
  group: (state) => state.group
};

const actions = {
  async getGroupSystem({ commit }){
    const { items } = await api.group().getUserGroupsInSystem()
    commit('setGroup', items)
  }
};

const mutations = {
  setGroup(state, value) {
    state.group = value
  },
};

export const group = {
  namespaced:
      true,
  state,
  getters,
  actions,
  mutations,
};
