<template>
  <div>
    <FormMain
      ref="formHeader"
      :key="fields.length"
      :data="fields"
      @get-forms="sendForm"
    />
    <v-row>
      <v-col cols="auto">
        <v-btn
          color="primary"
          @click="getForm"
        >
          Отправить на рассмотрение
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn color="primary">
          Сохранить черновик
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FormMain from "@/components/Form/FormMain";

export default {
  name: "FormPreview",
  components: {FormMain},
  props: {
    fields: {
      type: Array,
      default: () => []
    }
  }
}
</script>
<script setup>
import {ref} from "vue";

const formHeader = ref(null)

function getForm () {
  if (formHeader.value) {
    formHeader.value.getForm()
  }
}

function sendForm (inputs, valid) {
  if (!valid) {
    return
  }
}
</script>
