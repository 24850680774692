<template>
  <the-container>
    <template #title>
      <div class="ml-2">
        Анкеты
      </div>
    </template>
    
    
    <QuestionnaireTable
      :items="result"
      :form-fields="formFields"
      :total-count="total"
      :unique-org-names="uniqueOrgNames"
      :unique-users="uniqueUsers"
      @update-questionnaire="loadData"
      @filter-result="filterResult"
      @filter-questionnaire="loadFilterData"
      @update-page="loadPage"
      @update-search="updateSearch"
      @filter-questionnaire-id="filterQuestionnaireId"
      @filter-questionnaire-user="filterQuestionnaireUser"
      @filter-questionnaire-start-date="filterQuestionnaireStartDate"
      @filter-questionnaire-end-date="filterQuestionnaireEndDate"
    />
  </the-container>
</template>

<script setup>
import QuestionnaireTable from '@/components/Questionnaire/QuestionnaireTable';
import TheContainer from '@/components/TheContainer';

import { api } from '@/api/Api';
import { onMounted, ref } from 'vue';

const result = ref([]);
const allResults = ref([]);
const formFields = ref([]);
const page = ref(1);
const formIdValue = ref();
const total = ref(0);
const uniqueOrgNames = ref([]);
const uniqueUsers = ref([]);
const status = ref('');
const dataFrom = ref();
const dataTo = ref();
const userId = ref(null);
const searchValue = ref();
const isShowInject = ref(true); // Переменная для отображения загрузки

// Функция для загрузки страницы
const loadPage = async (page) => {
  loadData(page);
};

// Функция для загрузки данных
async function loadData(
  customPage = 1,
  customStatus,
  customPageSize = 20,
  customFormId,
  customUserId,
  customDataFrom,
  customDataTo,
  customSearchValue
) {
  isShowInject.value = true; // Показать индикатор загрузки
  const data = {
    page: customPage,
    status: customStatus || status.value,
    pageSize: customPageSize,
    formId: customFormId || formIdValue.value,
    userId: customUserId || userId.value || '',
    date_from: dataFrom?.value,
    date_to: dataTo?.value,
    q: customSearchValue || searchValue.value,
  };

  try {
    const {
      items,
      _meta: { totalCount },
    } = await api.result().getResults(data);

    allResults.value = [...allResults.value, ...items].filter(Boolean);
    total.value = totalCount;
    result.value = items;
    getUniqueOrgNames();
    setUniqueUsers();
  } catch (error) {
    // Handle errors
    console.error('Error loading data:', error);
  } finally {
    isShowInject.value = false; // Скрыть индикатор загрузки после завершения загрузки данных
  }
}

// Функция для обновления поиска
async function updateSearch(value) {
  const page = 1;
  const formId = formIdValue.value;
  const userIdValue = userId.value ? userId.value : '';
  const dataFromValue = dataFrom?.value;
  const dataToValue = dataTo?.value;

  const {
    items,
    _meta: { totalCount },
  } = await api.result().getResults({
    page,
    status: status.value,
    pageSize: 20,
    formId,
    userId: userIdValue,
    date_from: dataFromValue,
    date_to: dataToValue,
    q: value,
  });

  searchValue.value = value;
  allResults.value = [...allResults.value, ...items].filter(Boolean);
  total.value = totalCount;
  result.value = items;
}

// Функция для фильтрации результатов
function filterResult(formId) {
  result.value = allResults.value.filter((el) => el?.forma?.id === formId);
  formFields.value = [];

  if (formId) {
    page.value = formId && formId !== formIdValue.value ? 1 : page.value;
    formIdValue.value = formId;
    getFieldsById(formId);
    loadData(
      page.value,
      status.value,
      20,
      formIdValue.value,
      userId.value ? userId.value : '',
      dataFrom.value,
      dataTo.value,
      searchValue.value
    );
  } else {
    page.value = 1;
    formIdValue.value = undefined;
    loadData(
      page.value,
      status.value,
      20,
      formIdValue.value,
      userId.value ? userId.value : '',
      dataFrom.value,
      dataTo.value,
      searchValue.value
    );
  }
}

// Функция для загрузки данных с фильтром
function loadFilterData(val) {
  status.value = val;
  allResults.value = [];
  result.value = [];
  loadData(
    1,
    val,
    20,
    formIdValue.value,
    userId.value ? userId.value : '',
    dataFrom?.value,
    dataTo?.value,
    searchValue?.value
  );
}

function filterQuestionnaireId(id) {
  id
    ? (result.value = allResults.value.filter((el) => el?.id === id))
    : (result.value = allResults.value);
}

function filterQuestionnaireUser(userIdVal) {
  userId.value = userIdVal;
  allResults.value = [];
  result.value = [];
  loadData(
    1,
    status.value,
    20,
    formIdValue.value,
    userIdVal,
    dataFrom?.value,
    dataTo?.value,
    searchValue?.value
  );
}

function filterQuestionnaireStartDate(date_from) {
  dataFrom.value = date_from.startsWith('1970') ? undefined : String(date_from);
  if (date_from.startsWith('1970')) {
    loadData(
      1,
      status.value,
      20,
      formIdValue.value,
      userId.value ? userId.value : '',
      undefined,
      dataTo?.value,
      searchValue?.value
    );
  } else {
    loadData(
      1,
      status.value,
      20,
      formIdValue.value,
      userId.value ? userId.value : '',
      dataFrom,
      dataTo?.value,
      searchValue?.value
    );
  }
}

function filterQuestionnaireEndDate(date_to) {
  if (date_to.startsWith('1970')) {
    dataTo.value = undefined;
    loadData(
      1,
      status.value,
      20,
      formIdValue.value,
      userId.value ? userId.value : '',
      dataFrom?.value,
      undefined,
      searchValue?.value
    );
  } else {
    dataTo.value = String(date_to);
    loadData(
      1,
      status.value,
      20,
      formIdValue.value,
      userId.value ? userId.value : '',
      dataFrom?.value,
      dataTo,
      searchValue?.value
    );
  }
}

const getUniqueOrgNames = () => {
  const orgNamesSet = new Set();
  allResults.value.forEach((item) => {
    orgNamesSet.add(item.org_name_value);
  });
  uniqueOrgNames.value = Array.from(orgNamesSet);

};

const setUniqueUsers = () => {
  const userIdsSet = new Set();
  const uniqueUsersArray = [];

  allResults.value.forEach((item) => {
    if (item.user !== null) { // Добавлена проверка на наличие пользователя
      const userId = item.user.id;

      if (!userIdsSet.has(userId)) {
        const userInfo = {
          id: userId,
          name: item.user.fio,
        };
        uniqueUsersArray.push(userInfo);
        userIdsSet.add(userId);
      }
    }
  });

  uniqueUsers.value = uniqueUsersArray;
};

// Функция для получения полей по ID формы
async function getFieldsById(formId) {
  try {
    await api
      .formFields()
      .getFieldsByFormID(formId, page.value)
      .then((data) => {
        formFields.value = [...data?.items, ...formFields.value];
        if (data?._meta.pageCount > page.value) {
          page.value = page.value + 1;
          getFieldsById(formIdValue.value, data?._meta.currentPage + 1);
        }
      });
  } catch (err) {
    console.log(err);
  }
}

// Хук монтирования компонента
onMounted(() => {
  loadData();
});
</script>
