<template>
  <v-app-bar
    elevation="3"
    class="pa-0 ma-auto"
  >
    <div
      class="main-container"
    >
      <v-app-bar-nav-icon
        v-if="isMobile"
        @click.stop="drawer = !drawer"
      />
      <div>
        <p class="logo">
          <router-link to="/">
            <SvgBase
              width="129"
              height="35"
            >
              <SvgLogo />
            </SvgBase>
          </router-link>
        </p>
      </div>
      <v-tabs
        v-if="!isMobile"
        class="tabs flex-grow-0"
      >
        <v-tab
          v-for="(tab, index) in tabs"
          :key="index"
          :to="tab.to"
          class="flex-grow-0"
        >
          {{ tab.title }}
        </v-tab>
      </v-tabs>
      <v-row class="px-0 mx-0 align-center justify-end flex-grow-1 flex-nowrap">
        <div>
          <the-notifications />
        </div>
        <div>
          <v-btn
            id="button"
            color="primary"
            icon="mdi-account-outline"
            variant="text"
            class="user"
            @click="openUserInfoDialog"
          />
          <v-tooltip
            v-if="userName || userRole"
            activator="#button"
            location="bottom"
          >
            <span class="text-center">{{ userName }} <br>({{ userRole }})</span>
          </v-tooltip>
        </div>
        <div>
          <v-btn
            id="button-info"
            color="primary"
            icon="mdi-help-circle-outline"
            variant="text"
            class="user ma-0 pa-0"
            @click="openFeedbackForm"
          />
          <v-tooltip
            activator="#button-info"
            location="bottom"
          >
            Техническая поддержка
          </v-tooltip>
        </div>
        <div>
          <v-btn
            icon="mdi-exit-to-app"
            color="primary"
            @click="logout"
          />
        </div>
      </v-row>
    </div>
  </v-app-bar>
  <v-navigation-drawer
    v-model="drawer"
    bottom
    :temporary="true"
  >
    <v-list>
      <v-list-item
        v-for="(tab, index) in tabs"
        :key="index"
        :to="tab.to"
      >
        <v-list-item-title>
          {{ tab.title }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
  <UserInfo ref="userInfoDialog" />

  <FeedbackDialog ref="feedbackDialog" />
</template>

<script>
export default {
  name: "TheHeader",
  computed: {
    isMobile () {
      return this.$vuetify.display.xs || this.$vuetify.display.sm
    }
  },
  watch: {
    isMobile () {
      if (!this.isMobile) {
        this.drawer = false
      }
    }
  }
};
</script>
<script setup>
import store from "@/store";
import { useRouter } from "vue-router";
import { computed, ref } from "vue";
import SvgBase from "@/components/Svg/SvgBase";
import SvgLogo from "@/components/Svg/SvgLogo";
import UserInfo from "@/components/User/UserInfo";
import FeedbackDialog from "@/components/User/FeedbackDialog";
import TheNotifications from "@/components/TheNotifications";

defineProps({
  tabs: {
    type: Array,
    default: () => []
  }
})

const router = useRouter()

const userInfoDialog = ref(null)
const feedbackDialog = ref(null)

const drawer = ref(false)

const userName = computed(() => store.getters["user/userName"])
const userRole = computed(() => store.getters["user/userRoleInString"])

function logout() {
  store.dispatch('auth/logout')
  router.go(0)
}
function openUserInfoDialog () {
  if (userInfoDialog.value) {
    userInfoDialog.value.open()
  }
}

function openFeedbackForm() {
  if (feedbackDialog.value) {
    feedbackDialog.value.dialogToggle()
  }
}
</script>

<style lang="scss" scoped>
.main-container {
  width: 1174px;
  min-height: 64px;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  position: relative;
  align-items: center;
  margin: 0 auto;
  @media (max-width: 1279.95px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (max-width: 599.95px) {
    padding-left: 15px;
    padding-right: 15px;
  }

}

.logo {
  flex: 0 0 auto;
  max-width: 138px;
  margin-right: auto;
  line-height: normal;
}

.tabs {
  flex-grow: 1;
  margin-left: 36px;
}
.user {
  margin-left: 0;
  text-transform: inherit;
  &__name {
    display: block;
    max-width: 250px;
  }
}
</style>
