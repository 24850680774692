<template>
  <v-btn
    color="primary"
    size="small"
    class="ma-2"
    @click="openDialog"
  >
    Настроить правила
  </v-btn>
  <v-dialog v-model="dialog">
    <v-form ref="formRef">
      <v-card width="500">
        <v-card-text>
          <v-row
            :no-gutters="true"
            class="checkbox-row"
          >
            <div class="checkbox-with-label">
              <v-checkbox
                v-model="ruleProp.required"
                true-value="[&quot;required&quot;]"
                false-value=""
                hide-details="auto"
              />
              <span>Обязательное поле</span>
            </div>
          </v-row>

          <!-- Правило для телефона -->
          <div class="select-rule-box">
            <v-row
              :no-gutters="true"
              class="checkbox-row"
            >
              <div class="checkbox-with-label">
                <v-checkbox
                  v-model="ruleProp.phoneNumber"
                  true-value="[&quot;phoneNumber&quot;]"
                  false-value=""
                  hide-details="auto"
                  @change="checkPhoneCheckbox"
                />
                <span>Номер телефона</span>
              </div>
            </v-row>

            <!-- Правило для inn -->
            <v-row
              :no-gutters="true"
              class="checkbox-row"
            >
              <div class="checkbox-with-label">
                <v-checkbox
                  v-model="ruleProp.inn"
                  true-value="[&quot;inn&quot;]"
                  false-value=""
                  hide-details="auto"
                  @change="checkInnCheckbox"
                />
                <span>ИНН</span>
              </div>
            </v-row>

            <!-- Правило для FizInn -->
            <v-row
              :no-gutters="true"
              class="checkbox-row"
            >
              <div class="checkbox-with-label">
                <v-checkbox
                  v-model="ruleProp.fizInn"
                  true-value="[&quot;fizInn&quot;]"
                  false-value=""
                  hide-details="auto"
                  @change="checkFizInnCheckbox"
                />
                <span>ИНН физического лица</span>
              </div>
            </v-row>

            <!-- Правило для yurInn -->
            <v-row
              :no-gutters="true"
              class="checkbox-row"
            >
              <div class="checkbox-with-label">
                <v-checkbox
                  v-model="ruleProp.yurInn"
                  true-value="[&quot;yurInn&quot;]"
                  false-value=""
                  hide-details="auto"
                  @change="checkYurInnCheckbox"
                />
                <span>ИНН юридического лица</span>
              </div>
            </v-row>
          </div>
          <v-row
            v-if="
              !ruleProp.phoneNumber &&
                !ruleProp.fizInn &&
                !ruleProp.yurInn &&
                !ruleProp.inn
            "
            :no-gutters="true"
          >
            <v-col>
              <span>Максимальная длина строки</span>
              <v-text-field
                v-model="ruleProp.max"
                color="primary"
                variant="outlined"
                density="compact"
                type="number"
                placeholder="Введите число"
                clearable
              />
            </v-col>
          </v-row>
          <v-row
            v-if="false"
            :no-gutters="true"
          >
            <v-col>
              <span>Минимальная и максимальная длинна строки</span>
              <v-text-field
                v-model="ruleProp.minMax"
                color="primary"
                variant="outlined"
                density="compact"
                placeholder="Введите числа"
                clearable
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="save">
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script setup>
import {
  props as baseProps,
  emit as baseEmit,
} from "@/composables/form/usePropsForm";
import { findRuleProperty, useForm } from "@/composables/form/useForm";
import { ref } from "vue";

const props = defineProps({
  ...baseProps,
});
const emit = defineEmits(baseEmit);

const dialog = ref(false);
const formRef = ref(null);
const ruleProp = ref({
  max: setDef("string"),
  required: setDef("required"),
  selectedInnType: null,
  phoneNumber: setDef("phoneNumber"),
  fizInn: setDef("fizInn"),
  yurInn: setDef("yurInn"),
  inn: setDef("inn"),
});

function setDef(type) {
  let value;
  if (props.default_value !== undefined && props.default_value !== null) {
    if (Object.prototype.hasOwnProperty.call(props.default_value, "value")) {
      value = props.default_value.value;
    } else if (
      typeof props.default_value === "object" &&
      Reflect.has(props.default_value, "value")
    ) {
      value = props.default_value.value;
    } else if (typeof props.default_value === 'string') {
      value = props.rules;
    }
  }

  // const value = props.default_value.value || props.rules;
  if (typeof value === "string") {
    return value?.split(", ").find((value) => value.includes(type));
  }

  const pr = {
    rules: value,
  };
  const item = findRuleProperty(pr, type);

  if (!item) {
    return "";
  }

  if (type === "required") {
    return '["required"]';
  }

  if (type === "phoneNumber") {
    return '["phoneNumber"]';
  }

  if (type === "fizInn") {
    return '["fizInn"]';
  }

  if (type === "yurInn") {
    return '["yurInn"]';
  }

  if (type === "inn") {
    return '["inn"]';
  }

  return item[1]?.max ?? "";
}
function openDialog() {
  dialog.value = true;
}
async function save() {
  const { valid } = await formRef.value.validate();
  if (!valid) {
    return;
  }

  const arr = Object.entries(ruleProp.value).map(([key, value]) => {
    if (!value) {
      return;
    }

    switch (key) {
      case "required": {
        return value;
      }
      case "phoneNumber": {
        return `${value}, ["string",{"max": "18"}]`;
      }
      case "fizInn": {
        return `${value}, ["string",{"max": "12"}]`;
      }
      case "yurInn": {
        return `${value}, ["string",{"max": "10"}]`;
      }
      case "inn": {
        return `${value}, ["string",{"min": "10"},{"max": "12"}]`;
      }
      case "max": {
        if (!value) {
          return null;
        }

        return `["string",{"max": "${value}"}]`;
      }
    }
  });

  inputModel.value = `[${arr.filter(Boolean).join(", ")}]`;

  dialog.value = false;
}

function checkPhoneCheckbox() {
  if (ruleProp.value.phoneNumber) {
    ruleProp.value.inn = false;
    ruleProp.value.fizInn = false;
    ruleProp.value.yurInn = false;
  }
}

function checkInnCheckbox() {
  if (ruleProp.value.inn) {
    ruleProp.value.phoneNumber = false;
    ruleProp.value.fizInn = false;
    ruleProp.value.yurInn = false;
  }
}

function checkFizInnCheckbox() {
  if (ruleProp.value.fizInn) {
    ruleProp.value.phoneNumber = false;
    ruleProp.value.yurInn = false;
    ruleProp.value.inn = false;
  }
}

function checkYurInnCheckbox() {
  if (ruleProp.value.yurInn) {
    ruleProp.value.phoneNumber = false;
    ruleProp.value.fizInn = false;
    ruleProp.value.inn = false;
  }
}

const { inputModel } = useForm(props, emit);
</script>

<style lang="scss">
.checkbox-with-label {
  display: flex;
  align-items: center;
}

.checkbox-row {
  margin-bottom: 10px;
}

.select-rule-box {
  border: 1px solid grey;
  border-radius: 4px;
  margin: 8px 0 16px 0;
}
</style>
