<template>
  <TheAgreement v-if="!hasAgreement" />
  <v-layout v-if="hasAgreement">
    <TheHeader :tabs="tabs" />
    <v-main class="main">
      <v-col class="pa-0 ma-0 h-100 d-flex flex-column">
        <div class="content">
          <router-view :key="$route.fullPath" />
        </div>
        <div class="footer">
          <TheFooter />
        </div>
      </v-col>
    </v-main>
  </v-layout>
</template>

<script>
export default {
  name: "DefaultLayout",
};
</script>
<script setup>
import TheHeader from "@/components/TheHeader";
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import {ADMIN, ADMIN_COMPETITION, EXPERT, MANAGER, USER} from "@/enums/roles";
import TheAgreement from "@/components/TheAgreement";
import TheFooter from "@/components/TheFooter";

const store = useStore()
const tabsToUser = [
  {
    title: 'Конкурсы',
    to: '/service'
  },
  {
    title: 'Отборы',
    to: '/selections'
  },
  {
    title: 'Мои заявки',
    to: '/my-orders'
  }
]

const tabsToExpert = [
  {
    title: 'Конкурсы',
    to: '/service'
  },
  {
    title: 'Анкеты',
    to: '/questionnaires'
  }
]

const tabsToAdmin = [
  {
    title: 'Конкурсы',
    to: '/service'
  },
  {
    title: 'Пользователи и роли',
    to: '/users-and-roles'
  }
]
const tabsToAdminCompetition = [
  {
    title: 'Конкурсы',
    to: '/service'
  }
]
const tabsToManger = [
  {
    title: 'Конкурсы',
    to: '/service'
  },
  {
    title: 'Данные команды',
    to: '/orders'
  },
  {
    title: 'Анкеты',
    to: '/questionnaires'
  }
]

const currentRoles = computed(() => {
  return store.getters["user/currentRoles"]
})
const tabs = computed(() => {
  if (currentRoles.value.length === 0) {
    return tabsToUser
  }

  const flatTabs = currentRoles.value
    .flatMap(role => {
      if (role === ADMIN) {
        return tabsToAdmin
      }

      if (role === MANAGER) {
        return tabsToManger
      }

      if (role === EXPERT) {
        return tabsToExpert
      }

      if (role === ADMIN_COMPETITION) {
        return tabsToAdminCompetition
      }

      if (role === USER) {
        return tabsToUser
      }
    })

  return filterTabs(flatTabs)
})

const userInfo = computed(() => store.getters["user/userInfo"])
const hasAgreement = computed(() => userInfo.value?.agreement === 'Y')

function filterTabs (tabs) {
  const filteredTabs = []
  const titleTabs = new Set(tabs.map(tab => tab?.title))
  titleTabs?.forEach(title => {
    const findItem = tabs.find(tab => tab?.title === title)
    if (findItem) {
      filteredTabs.push(findItem)
    }
  })

  return filteredTabs
}

onMounted(() => {
  store.dispatch('group/getGroupSystem')
})
</script>

<style lang="scss" scoped>
.main{
  background-color: #fafafa;
  height: auto;
}

.content {
  flex: 1 0 auto;
}

.footer {
  flex: 0 0 auto;
}
</style>
