import { axiosCustom, axiosNotifications } from "@/plugins/axios";

export class Message {
  getMessages(params) {
    return axiosCustom
      .get(`/api/messages?${params}`)
      .then((response) => response.data);
  }

  getBatchMessages(params) {
    return axiosNotifications
      .get(`/messages?${params}`)
      .then((response) => response.data);
  }

  updateMessage(messageId, form) {
    return axiosNotifications
      .put(`/messages/${messageId}`, form)
      .then((response) => response.data);
  }

  sendMessage(form) {
    return axiosCustom
      .post("/api/messages", form)
      .then((response) => response.data);
  }

  sendBatchMessages(form) {
    return axiosNotifications
      .post("/messages/batch", form)
      .then((response) => response.data);
  }

  deleteMessage(messageId, body) {
    return axiosNotifications({
      method: "delete",
      url: `/messages/${messageId}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: body,
    }).then((response) => response.data);
  }
}
