export const props = {
  default_value: {
    type: [String, Number, Array, Boolean],
    default: null,
  },
  id: {
    type: [String, Number],
    default: null,
  },
  type: {
    type: String,
    default: "",
  },
  name: {
    type: String,
    default: "",
  },
  rules: {
    type: [Array, String],
    default: null,
  },
  descr: {
    type: String,
    default: "",
  },
  note: {
    type: String,
    default: "",
  },
  title: {
    type: String,
    default: "",
  },
  options: {
    type: Array,
    default: () => [],
  },
  multiple: {
    type: Number,
    default: 0,
  },
  group_id: {
    type: [String, Number],
    default: null,
  },
  sort: {
    type: [String, Number],
    default: null,
  },
};

export const emit = ["update-value"];
