import {axiosNotifications} from "@/plugins/axios";
import {DEFAULT_ID_PROJECT} from "@/constants";

export const getUrlSearchParams = (data) => {
    return new URLSearchParams({
        ...data
    })
}

export const DEFAULT_PARAMS = new URLSearchParams({
    project: DEFAULT_ID_PROJECT,
}).toString()

export class Notifications {
    getSettingsNotifications () {

        return axiosNotifications.get(`/user/getparams?${DEFAULT_PARAMS}`)
            .then((response) => response.data);
    }

    changeNotificationsStatus(data) {
        const headers = {
            'Content-type': 'multipart/form-data',
        }

        return axiosNotifications.post('/user/setparams', {...data, project: DEFAULT_ID_PROJECT}, {headers})
    }

    readNotify(data) {
        const headers = {
            'Content-type': 'multipart/form-data',
        }

        return axiosNotifications.post('/messages/read', data, {headers})

    }

    getNewNotifications () {
        return axiosNotifications.get(`/messages/getnew?${getUrlSearchParams({project: DEFAULT_ID_PROJECT})}`)
    }

    getContext() {
        return axiosNotifications.get(`/context`, {project: DEFAULT_ID_PROJECT})
    }

    getMessages(data) {
        return axiosNotifications.get(`/messages?${getUrlSearchParams({...data, project: DEFAULT_ID_PROJECT})}`)
    }

    sendMessage(data) {
        const headers = {
            'Content-type': 'multipart/form-data',
        }
        return axiosNotifications.post('/messages', data, {headers})
    }
}
