<template>
  <v-file-input
    :id="`inputCustom${id}`"
    v-model="inputModel"
    :rules="[...rulesCommon,...fileRules]"
    :accept="acceptedFileTypes"
    reverse
    :hint="descr"
    :multiple="multiple"
    :persistent-hint="Boolean(descr)"
    clearable
    single-line
    variant="outlined"
  >
    <template
      v-if="canViewFile"
      #append
    >
      <a
        :href="fileData"
        target="_blank"
      >
        <v-icon size="x-large">
          mdi-image
        </v-icon>
      </a>
    </template>
  </v-file-input>
</template>

<script setup>
import {props as baseProps, emit as baseEmit} from "@/composables/form/usePropsForm";
import {useForm} from "@/composables/form/useForm";
import {computed, onMounted, ref, watch} from "vue";
import {convertMb} from "@/utils/common";

const props = defineProps({
  ...baseProps,
  fileData: {
    type: String,
    default: '',
  },
});
const emit = defineEmits(baseEmit)

const {rulesCommon, inputModel, fileTypes, fileSize} = useForm(props, emit);

const acceptedFileTypes = computed(() => {
  if (!Array.isArray(fileTypes.value)) {
    return ''
  }

  return fileTypes.value.map(type => `.${type}`).join(',')
})

const fileRules = ref([
  (value) => {
    return fileTypes.value || isValidFileType(value[0]?.name) || `Формат файла не ${acceptedFileTypes.value}`
  },
  (value) => {
    return typeof value === 'object' ||
      !value ||
      value[0]?.size <= fileSize.value ||
      `Размер файла превышает ${convertMb(fileSize.value)}`
  }
])
const canViewFile = ref(false)

function isValidFileType(fileName) {
  const fileType = fileName.toLowerCase().split('.').pop()
  if (Array.isArray(fileTypes.value)) {
    return fileTypes.value.includes(fileType)
  }

  return fileTypes.value
}

watch(inputModel, () => {
  canViewFile.value = inputModel.value.length === 0 && Boolean(props.fileData)
})

onMounted(() => {
  canViewFile.value = Boolean(props.fileData)
})

</script>

<style scoped>
a {
  text-decoration: auto;
}
</style>
