<template>
  <v-combobox
    :id="`inputCustomCombobox${id}`"
    ref="combobox"
    :model-value="inputComboboxModel"
    :menu-props="true"
    :items="optionsList"
    :rules="rulesCommon"
    return-object
    item-title="name"
    item-value="value"
    :hint="descr"
    :persistent-hint="Boolean(descr)"
    :multiple="Boolean(multiple)"
    variant="outlined"
    @update:model-value="setNewValue"
  >
    <template #item="{item: {raw}}">
      <v-list-item
        :disabled="formatPrimitiveChar(raw.disabled)"
        class="select__name"
        @click.prevent="setNewValue(raw)"
      >
        {{ raw.name }}
        <v-tooltip
          v-if="raw.description"
          activator="parent"
          location="top"
        >
          <div v-html="raw.description" />
        </v-tooltip>
      </v-list-item>
    </template>
  </v-combobox>
  <template v-if="isShowOtherInput">
    <p class="text-body-1 ma-0">
      Другое *
    </p>
    <v-text-field
      :id="`inputTextCombobox${id}`"
      v-model="inputModelText"
      :rules="rulesCommon"
      :persistent-hint="true"
      hint="Введите другое значение"
      color="primary"
      variant="outlined"
      class="text-pre-wrap"
    />
  </template>
</template>

<script setup>
import { props as baseProps, emit as baseEmit } from "@/composables/form/usePropsForm";
import { useForm } from "@/composables/form/useForm";
import {computed, nextTick, ref, watch} from "vue";
import {formatPrimitiveChar} from "@/utils/common";

const props = defineProps({
  ...baseProps
});
const emit = defineEmits(baseEmit)

const combobox = ref(null)
const inputModelText = ref('')
const inputComboboxModel = ref()
const optionsList = ref([...props.options, { name: 'Другое', id: 'other', value: 'Другое' }])

function setNewValue(value){
  inputComboboxModel.value = value
}
const { rulesCommon , inputModel} = useForm(props, emit);

const isShowOtherInput = computed(() => inputComboboxModel.value?.id === 'other')

watch(isShowOtherInput, () => {
  if (isShowOtherInput.value) {
    nextTick(() => {
      document.getElementById(`inputTextCombobox${props.id}`).click()
      inputModel.value = inputModelText.value
    })
  } else {
    inputModel.value = inputComboboxModel.value.value
  }
})

watch(inputModel, () => {
  document.getElementById(`inputCustomCombobox${props.id}`).blur()
})

watch(inputComboboxModel, () => {
  if (inputComboboxModel.value?.id !== 'other' && inputComboboxModel.value) {
    return inputModel.value = inputComboboxModel.value?.value
  }

  if (inputComboboxModel.value?.id === 'other') {
    return inputModel.value = inputModelText.value?.value
  }
})

watch(inputModelText, () => {
  if (inputComboboxModel.value?.id === 'other') {
    return inputModel.value = inputModelText.value
  }
})
</script>
