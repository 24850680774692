<template>
  <div>
    <div class="footer-container">
      <v-card
        elevation="3"
        :class="['pa-0 ma-auto py-5', ($vuetify.display.lg || $vuetify.display.xl) && contest && hasMargin && isUser ? 'lg-footer' : '']"
      >
        <the-container style-container="background: none !important">
          <v-row class="pa-0 ma-0 justify-space-between flex-md-nowrap flex-md-row flex-column align-center align-md-start text-center text-md-left justify-center">
            <v-col
              class="logo pa-0 ma-0 align-center align-md-start justify-center justify-md-start d-flex"
              cols="3"
            >
              <router-link to="/">
                <SvgBase
                  width="111"
                  height="31"
                >
                  <SvgLogoFooter />
                </SvgBase>
              </router-link>
            </v-col>
            <v-col
              cols="5"
              class="w-100 pa-0 ma-0 d-flex flex-column text-caption flex-grow-1"
            >
              <v-col
                v-if="contest"
                class="pa-0 ma-0 flex-column d-flex"
              >
                <div v-if="contest?.name">
                  {{ contest?.name }}
                </div>
                <a
                  v-if="contest?.more_link"
                  :href="contest?.more_link"
                  target="_blank"
                  class="text-blue-darken-4"
                >
                  Сайт конкурса
                </a>
                <a
                  v-if="contest?.email"
                  :href="`mailto:${contest?.email}`"
                  class="text-blue-darken-4"
                >
                  {{ contest?.email }}
                </a>
              </v-col>
            </v-col>
            <v-col
              class="pa-0 ma-0 d-flex flex-column align-center align-md-start justify-end text-caption text-grey"
              cols="4"
            >
              <div
                v-for="(link, index) in links"
                :key="index"
                class="text-grey cursor-pointer"
                @click="() => openFile(link)"
              >
                {{ link.title }}
              </div>
              <div
                v-if="isDevDomain && (isAdmin || isManager)"
                class="text-grey cursor-pointer"
                @click="goToElk2"
              >
                ЕЛК 2.0.
              </div>
              <div class="mt-4">
                © ФОНД НТИ, {{ getYear() }}
              </div>
            </v-col>
          </v-row>
        </the-container>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheFooter",
  computed: {
    isMobile () {
      return this.$vuetify.display.xs || this.$vuetify.display.sm
    },
    isDevDomain () {
      const fullUrl = document.location
      let domain = (new URL(fullUrl));
      return domain !== process.env.VUE_APP_PROD_HOST
    },
  },
};
</script>
<script setup>
import {computed, onMounted, ref, watch} from "vue"
import SvgBase from "@/components/Svg/SvgBase"
import SvgLogoFooter from "@/components/Svg/SvgLogoFooter"
import {useStore} from "vuex";
import {api} from "@/api/Api";
import TheContainer from "@/components/TheContainer";
import {useRoute} from "vue-router/dist/vue-router";

const store = useStore()

const route = useRoute()

const isAdmin = computed(() => store.getters["user/isAdmin"])
const isManager = computed(() => store.getters["user/isManager"])

const defaultAgreementLinks = ref([
  {
    title: 'Пользовательское соглашение',
    href: '/Пользовательское соглашение.pdf'
  },
  {
    title: 'Политика конфиденциальности',
    href: '/Политика конфиденциальности.pdf'
  }
])
const links = ref([])
const hasMargin  = ref(false)

const isUser = computed(() => store.getters["user/isUser"])
const contest = computed(() => store.getters['contest/contest'])
const routeName = computed(() => route?.name)


defineProps({
  tabs: {
    type: Array,
    default: () => []
  }
})

function getYear() {
  const date = new Date()
  return date.getFullYear()
}

async function openFile(link) {
  let fileLink = link.href
  if (link.isBlobFile) {
   await api.upload().getFile(link.href).then((response) => {
      const fileData = URL.createObjectURL(response)
      fileLink = fileData
      window.open(fileLink, '_blank').focus();
    })
  } else {
    window.open(fileLink, '_blank').focus();
  }
}

function getLinks() {
  if (contest.value?.file_agreement) {
    links.value = [...defaultAgreementLinks.value, {
      title: 'Согласие на обработку персональных данных',
      href: contest.value?.file_agreement,
      isBlobFile: true
    }]
  } else {
    links.value = [...defaultAgreementLinks.value]
  }
}

watch(contest, () => {
  getLinks()
})

function setMarginFooter() {
  hasMargin.value = routeName.value === 'serviceId' || routeName.value === 'selectionsServiceId'
}

function goToElk2() {
  window.open(process.env.VUE_APP_ELK_V2, '_blank');
}

watch(routeName, () => {
  setMarginFooter()
})

onMounted(() => {
  getLinks()
  setMarginFooter()
})
</script>
<style lang="scss">
.footer-container {
  .lg-footer {
    margin-bottom: 92px !important;
  }
}
</style>
