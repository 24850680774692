<template>
  <div v-if="visible">
    <h4
      v-if="title"
      class="mb-2"
    >
      {{ title }}
    </h4>
    <div
      class="body"
      v-html="html"
    />
  </div>
</template>

<script>
export default {
  name: "ServiceBodyItem",
};
</script>

<script setup>
// eslint-disable-next-line no-undef
defineProps({
  title: {
    type: String,
    default: ''
  },
  html: {
    type: String,
    default: ''
  },
  visible: {
    type: Boolean,
    default: false
  }
});


</script>

<style lang="scss">
.jss173 p, .jss173 h3 {
  color: #000000;
}
.jss173 p {
  margin-top: 0;
}


.body {
  margin-bottom: 1em;
  & > h4 {
    margin-top: 0;
    margin-bottom: 8px;
  }

  & > p {
    margin-top: 0;
    color: #000000;
  }
  @media (max-width:599.95px) {
    p, h4 {
      font-size: 16px !important;
      line-height: 22px!important;
    }
  }
}
</style>