<template>
  <v-dialog
    :model-value="true"
    persistent
  >
    <v-card>
      <v-card-title class="d-flex justify-center">
        Пользовательское соглашение
      </v-card-title>
      <v-card-text>
        <div>Наш сайт использует cookie-файлы.</div>
        <div>
          Продолжая им пользоваться Вы принимаете условия
          <span
            class="text-blue-darken-4 cursor-pointer"
            @click="() => openFile('/Пользовательское соглашение.pdf')"
          >
            Пользовательского соглашения
          </span>
          и
          <span
            class="text-blue-darken-4 cursor-pointer"
            @click="() => openFile('/Политика конфиденциальности.pdf')"
          >
            Политики конфиденциальности
          </span>.
        </div>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn
          color="primary"
          @click="accept"
        >
          Принять
        </v-btn>
        <v-btn
          color="red"
          @click="logout"
        >
          Отказаться
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { api } from "@/api/Api";
import { computed } from "vue";

const store = useStore()
const router = useRouter()

const userId = computed(() => {
  return store.getters["user/currentUserId"]
})

async function updateUserInfo () {
  const form = new FormData()
  form.append('agreement', 'Y')
  await api.user().updateInfoByUser(userId.value, form)
}

async function accept () {
  await updateUserInfo()
  await store.dispatch('user/getUserInfo')
}

function logout () {
  store.dispatch('auth/logout')
  router.push('/login')
}

function openFile(fileLink) {
  window.open(fileLink, '_blank').focus();
}
</script>

<script>
export default {
  name: "TheAgreement"
}
</script>
