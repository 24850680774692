import { createStore } from 'vuex'
import { auth } from "@/store/auth";
import { user } from "@/store/user";
import { group } from "@/store/group";
import { contest } from "@/store/contest";

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    user,
    group,
    contest
  }
})
