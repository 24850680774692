// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import { ru } from "vuetify/lib/locale";

export default createVuetify({
  locale: {
    locales: { ru },
    current: 'ru',
  },
  theme: {
    themes: {
      light: {
        colors: {
          primary: '#1A2574',
        }
      },
    }
  }
  }
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
)
