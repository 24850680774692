<template>
  <v-radio-group
    :id="`inputCustom${id}`"
    :model-value="inputModel"
    :hint="descr"
    :persistent-hint="Boolean(descr)"
    :rules="rulesCommon"
    variant="outlined"
  >
    <v-radio
      v-for="radio in options"
      :key="radio.value"
      :value="radio.value"
      @change="setValue(radio.value)"
    >
      <template #label>
        <span>{{ radio.name }}</span>
      </template>
    </v-radio>
  </v-radio-group>
</template>

<script setup>
import { props as baseProps, emit as baseEmit } from "@/composables/form/usePropsForm";
import { useForm } from "@/composables/form/useForm";

const props = defineProps({
  ...baseProps
});
const emit = defineEmits(baseEmit)

function setValue(value){
  inputModel.value = value
}

const { rulesCommon, inputModel } = useForm(props, emit);
</script>
