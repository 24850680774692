<template>
  <TheContainer v-if="!load">
    <ServiceBackPage />
    <h1 class="container__title">
      {{ contest.name }}
    </h1>
    <v-row justify-md="space-between">
      <v-col
        cols="12"
        lg="6"
        class="text-body-1"
      >
        <ServiceBodyItem
          :visible="Boolean(contest.description)"
          :html="contest.description"
        />
        <ServiceBodyItem
          :visible="Boolean(contest.who_suits)"
          :html="contest.who_suits"
          title="Кому подходит"
        />
        <ServiceBodyItem
          :visible="Boolean(contest.requirements)"
          :html="contest.requirements"
          title="Требования к участникам"
        />
      </v-col>
      <v-col
        cols="12"
        lg="6"
      >
        <div v-if="contest?.stages?.length">
          <h4 class="mt-0 mb-2">
            Этапы {{ typeTitle }}
          </h4>
          <ServiceSupportItem
            v-for="(itemSup, index) in contest.stages"
            :key="index"
            v-bind="itemSup"
          />
        </div>
        <div v-if="contest?.support?.length">
          <h4 class="mt-0 mb-2">
            Меры поддержки
          </h4>
          <ServiceSupportItem
            v-for="(itemSup, index) in contest.support"
            :key="index"
            v-bind="itemSup"
          />
        </div>
      </v-col>
    </v-row>
    <v-row
      v-if="contest.more_link"
      class="container__more-link more-link"
      :no-gutters="true"
    >
      <span class="more-link__text">
        <a
          class="more-link__link"
          rel="noopener noreferrer"
          :href="contest.more_link"
          target="_blank"
        >
          Перейти на сайт {{ typeTitle }}
          <svg
            class="more-link__svg"
            width="8"
            height="14"
            viewBox="0 0 8 14"
            fill="none"
          >
            <path
              d="M1 1L7 7L1 13"
              stroke-linecap="round"
            />
          </svg>
        </a>
      </span>
    </v-row>
    <v-row>
      <v-col
        v-for="item in contest.subcontest"
        :key="item.name"
        md="3"
      >
        <ServiceItem :item="item" />
      </v-col>
    </v-row>

    <div style="height: 151px" />
    <div
      v-if="canLinkRegistration"
      class="control"
      :class="{
        'control--scroll': $vuetify.display.lg || $vuetify.display.xl,
      }"
    >
      <div style="height: 151px" />
      <v-row align-sm="center">
        <v-col
          cols="12"
          class="px-0 w-100"
        >
          <v-row class="align-center pa-4 px-2 w-100">
            <v-select
              v-if="(linksRegistration.length > 1 && !activeResult) || hasPlaceToApply"
              v-model="selectForm"
              :items="linksRegistration"
              label="Выберите форму"
              item-title="title"
              item-value="id"
              solo
              hide-details
              class="mr-sm-4 mr-xs-0 mb-2 mb-sm-0"
              style="width: fit-content;"
            />

            <v-btn
              color="primary"
              size="x-large"
              :rounded="0"
              :loading="loadingBtn"
              :disabled="!hasPlaceToApply || registrationCompleted"
              class="control__btn"
              @click="openPageForm"
            >
              <template #default>
                <span class="control__btn-text"> Подать заявку </span>
              </template>
            </v-btn>

            <!-- v-if="activeResult" -->
            <v-btn
              v-if="false" 
              color="primary"
              size="x-large"
              :rounded="0"
              :to="`/questionnaire/${activeResult.id}`"
              class="control__btn ml-4"
            >
              <template #default>
                <span class="control__btn-text"> Просмотреть анкету </span>
              </template>
            </v-btn>
            <v-col
              v-if="registrationCompleted"
              class="pa-0 ma-0 text-grey pl-4"
              style="z-index: 999"
            >
              Прием заявок окончен
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </TheContainer>
  <v-row
    v-else
    class="w-100 h-100 d-flex align-center justify-center"
  >
    <v-progress-circular
      indeterminate
      color="primary"
    />
  </v-row>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import moment from "moment";
import { computed, onMounted, onUnmounted, ref } from "vue";
import { api } from "@/api/Api";
import ServiceSupportItem from "@/components/Service/ServiceSupportItem";
import ServiceBodyItem from "@/components/Service/ServiceBodyItem";
import ServiceItem from "@/components/Service/ServiceItem";
import ServiceBackPage from "@/components/Service/ServiceBackPage";
import TheContainer from "@/components/TheContainer";
import { useStore } from "vuex";
import { REJECT_STATUS } from "@/utils/constants";

const router = useRouter();
const route = useRoute();
const store = useStore();

const contest = ref({});
const loadingBtn = ref(false);
const linkRegistration = ref(null);
const linksRegistration = ref([]);
const selectForm = ref(null);
const activeResult = ref(null);
const load = ref(false);

const hasPlaceToApply = ref(false);

const canLinkRegistration = computed(() => Boolean(linkRegistration.value));
const currentUserId = computed(() => store.getters["user/currentUserId"]);
const isSelection = computed(() => route.meta?.isSelections);
const typeTitle = computed(() => (isSelection.value ? "отбора" : "конкурса"));
const registrationCompleted = computed(() => {
  if (linksRegistration.value.length === 1) {
    const form = linksRegistration.value?.find((el) => !!el?.active);
    return form?.date_to === "0000-00-00 00:00:00"
      ? false
      : !moment(new Date()).isSameOrBefore(form?.date_to);
  }

  if (selectForm.value) {
    const form = linksRegistration.value?.find(
      (el) => !!el?.active && el?.id === selectForm.value
    );
    return form?.date_to === "0000-00-00 00:00:00"
      ? false
      : !moment(new Date()).isSameOrBefore(form?.date_to);
  }

  return false;
});

function getLinkRegistration(activeforms) {
  const form = activeforms.find((form) => form.active === 1);
  if (!form) {
    return null;
  }

  if (isSelection.value) {
    return `/selections/form/${form.id}`;
  }

  return `/form/${form.id}`;
}

async function getContest() {
  const { id } = route.params;
  contest.value = await api.contest().getContest(id);
  await store.dispatch("contest/setContest", contest.value);
  const { activeforms } = contest.value;
  linkRegistration.value = getLinkRegistration(activeforms);
  linksRegistration.value = activeforms.filter((el) => el.active === 1);
}

function openPageForm() {
  if (
    !linksRegistration.value.length > 1 &&
    typeof linkRegistration.value === "string"
  ) {
    return router.push(linkRegistration.value);
  }

  if (selectForm.value) {
    const link = isSelection.value
      ? `/selections/form/${selectForm.value}`
      : `/form/${selectForm.value}`;
    return router.push(link);
  }

  // if (linkRegistration.value) {
  //   return router.push(linkRegistration.value);
  // }
}

function getActiveResult(results) {
  const { activeforms } = contest.value;

  const filteredResults = results.filter(
    (result) => result.status !== REJECT_STATUS
  );
  activeResult.value = filteredResults.length > 0 ? filteredResults[0] : null;

  const secondObjectId = activeResult.value?.forma?.id;
  hasPlaceToApply.value = activeforms?.some(
    (item) => item.id !== secondObjectId
  );
  if (hasPlaceToApply.value) {
    selectForm.value = activeforms?.find(
      (item) => item.id !== secondObjectId
    ).id;
  }
}

async function loadActiveResult() {
  const { activeforms } = contest.value;
  const form = activeforms.find((form) => form.active === 1);
  if (!form) {
    return;
  }
  const { items } = await api
    .result()
    .getActiveResultsByFormIdAndUserId(form.id, currentUserId.value);
  getActiveResult(items);
}

async function init() {
  await getContest();
  await loadActiveResult();
}

onUnmounted(() => {
  store.dispatch("contest/setContest", null);
});

onMounted(() => {
  load.value = true;
  init().then(() => (load.value = false));
});
</script>

<style lang="scss">
.body {
  & > h4 {
    margin-top: 0;
    margin-bottom: 8px;
  }

  & > p {
    margin-top: 0;
    color: #000000;
  }
}
</style>

<style lang="scss" scoped>
.container {
  width: 1174px;
  margin: 0 auto;
  display: block;
  padding-top: 24px !important;
  padding-bottom: 80px !important;

  & > h1 {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  @media (max-width: 1279.95px) {
    width: 100%;
    padding-top: 40px;
    padding-left: 30px;
    padding-right: 30px;
    & > h1 {
      margin-top: 16px;
    }
  }

  @media (max-width: 599.95px) {
    padding-left: 15px;
    padding-right: 15px;
    & > h1 {
      font-size: 24px !important;
      line-height: 28px;
      margin-bottom: 24px !important;
    }
  }
}

.container {
  &__title {
    margin-top: 24px;
    margin-bottom: 24px;
    color: #000000;
    font-size: 40px;
    font-weight: normal;
    line-height: 55px;
    letter-spacing: 0;
  }

  &__more-link {
    margin-top: 24px;
    align-items: center;
    margin-bottom: 40px;
    @media (max-width: 1279.95px) {
      margin-top: 16px;
      margin-bottom: 32px;
    }
    @media (max-width: 599.95px) {
      height: inherit;
      margin-top: 0 !important;
      justify-content: center;
    }
  }
}
.more-link {
  &__text {
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
  }

  &__link {
    color: #1a2574;
    cursor: pointer;
    display: inline-flex;
    font-size: 18px;
    transition: 0.2s;
    align-items: center;
    text-decoration: none;
    background-color: transparent !important;

    &:hover {
      color: #2d3a96;
    }

    @media (max-width: 599.95px) {
      font-size: 14px;
      &:hover > svg > path {
        stroke: #2d3a96 !important;
      }
    }
    @media (max-width: 599.95px) {
      & > svg {
        margin-top: 2px;

        & > path {
          stroke: #1a2574;
        }
      }
    }
  }

  &__svg {
    height: 12px;
    margin-top: 3px;
    transition: 0.2s;
    margin-left: 10px;

    & > path {
      stroke: #1a2574;
    }
  }
}
.control {
  & > div:nth-child(1) {
    left: 0;
    width: 100%;
    position: absolute;
    transition: background-color 0.2s, box-shadow 0.2s;
  }

  & > div:nth-child(2) {
    height: 100%;
  }

  &__btn {
    height: 60px;
    min-width: 280px;
    box-shadow: none;
    border-radius: 2px;
    text-transform: initial;

    &:disabled {
      background: #f0f0f0;
    }
  }

  @media (max-width: 760px) {
    &__btn {
      width: 100%;
    }
  }
  @media (max-width: 599.95px) {
    &__btn {
      height: 44px;
      font-size: 14px;
    }
  }

  &__link {
    color: #1a2574;
    cursor: pointer;
    display: inline-flex;
    font-size: 18px;
    transition: 0.2s;
    margin-left: 64px;
    white-space: nowrap;
    text-decoration: none;
    text-transform: initial;

    & > span > svg {
      flex: 0 0 8px;
      max-width: 8px;
      margin-top: 3px;
      transition: 0.2s;
      margin-left: 16px;
    }

    &:hover {
      color: #2d3a96;
    }

    @media (max-width: 760px) {
      margin-top: 24px;
      margin-left: 0;
      display: flex;
      & > span {
        line-height: 27px !important;
      }
    }
    @media (max-width: 599.95px) {
      font-size: 14px;
      margin-top: 16px;
      & > span > svg {
        margin-top: 2px;
      }
      &:hover > span > svg > path {
        stroke: #2d3a96 !important;
      }
    }
  }

  &--scroll {
    left: 0;
    width: 100%;
    bottom: 0;
    z-index: 20;
    position: fixed;

    & > div:nth-child(1) {
      box-shadow: 0 -3px 20px rgba(0, 0, 0, 0.05);
      background-color: #fff;
    }

    & > div:nth-child(2) {
      width: 1170px;
      margin: 0 auto;
    }

    @media (max-width: 1279.95px) {
      & > div:nth-child(2) {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
      }
    }
    @media (max-width: 599.95px) {
      & > div:nth-child(2) {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }
}
</style>
