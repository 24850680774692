<template>
  <v-dialog
    v-model="dialog"
  >
    <v-card>
      <v-card-text>
        <v-form>
          <v-col>
            <v-row
              v-if="!contestManagers.length"
              justify="center"
            >
              <v-col cols="auto">
                <span class="text-caption">На конкурс не назначены менеджеры</span>
              </v-col>
            </v-row>
            <v-row
              v-for="manager in contestManagers"
              :key="manager?.id"
              align="center"
            >
              <v-col>
                <span class="text-body-1"> {{ manager?.fio }}</span>
              </v-col>
              <v-col cols="auto">
                <v-btn
                  :loading="isLoadingDelete"
                  variant="text"
                  color="red"
                  class="text-caption"
                  @click="deleteManager(manager.id)"
                >
                  Удалить менеджера
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              v-if="!!filteredAllManagers.length"
              class="mx-0"
            >
              <v-combobox
                id="managersList"
                v-model="selectedAddManagers"
                chips
                multiple
                name="managers"
                :items="filteredAllManagers"
                item-title="fio"
                item-value="id"
                hide-details="auto"
                :label="selectedAddManagers ? '' : 'Выберите менеджеров'"
                variant="underlined"
              />
            </v-row>
            <v-row
              v-if="!!filteredAllManagers.length"
              justify="center"
              class="pt-6"
            >
              <v-btn
                :disabled="!selectedAddManagers?.length"
                :loading="isLoading"
                color="primary"
                variant="text"
                size="small"
                @click.prevent="setManagers"
              >
                Добавить менеджера
              </v-btn>
            </v-row>
          </v-col>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row justify="center">
          <v-col cols="auto">
            <v-btn
              v-if="!isLoading"
              color="primary"
              variant="text"
              @click.prevent="close"
            >
              Закрыть
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";

import { api } from "@/api/Api";
import { MANAGER } from "@/enums/roles";
import { useToast } from "@/composables/useToast";
const { toast } = useToast()

const props = defineProps({
  contestId: {
    type: Number,
    default: null
  },
  contestManagers: {
    type: Array,
    default: () => []
  }
})

const emit = defineEmits(['get-contest-managers'])


const dialog = ref(false)
const allManagers = ref([])
const selectedAddManagers = ref()
const isLoading = ref(false)
const isLoadingDelete = ref(false)
const currentPage = ref(1)
const pageCount = ref()

const filteredAllManagers = computed(() => {
  return  allManagers.value.filter(manager => {
    const hasInArray = props.contestManagers.find(contestManager => contestManager?.id === manager?.id)
    if (!hasInArray) {
     return manager
    }
  })
})

function open () {
  dialog.value = true
}
function close () {
  selectedAddManagers.value = null
  dialog.value = false
}
async function getAllManagers () {
  const { items, _meta } = await api.user().getUsersByGroup(MANAGER, currentPage.value)

  if (currentPage.value === 1) {
    allManagers.value = items
  } else {
    allManagers.value = [...allManagers.value, ...items]
  }

  if (_meta.pageCount > currentPage.value) {
    currentPage.value = ++currentPage.value
    pageCount.value = _meta.pageCount

    await getAllManagers()
  }
}

async function setManagers () {
  const req = selectedAddManagers.value.map(managerId => {
    const form = new FormData()
    form.append('contest_id', props.contestId)
    form.append('user_id', managerId?.id)
    return api.contest().setManagerToContest(form)
  })
  isLoading.value = true
  try {
    await Promise.all(req)
    emit('get-contest-managers')
    if (selectedAddManagers.value?.length === 1) {
      toast.success('Менеджер успешно добавлен')
    } else {
      toast.success('Менеджеры успешно добавлены')
    }
    selectedAddManagers.value = null
  } catch (e) {
    toast.error('Произошла ошибка при добавлении')
  } finally {
    isLoading.value = false
  }

}

async function deleteManager (userId) {
  const form = new FormData()
  form.append('contest_id', props.contestId)
  form.append('user_id', userId)
  isLoadingDelete.value = true
  try {
    await api.contest().deleteManagerToContest(form)
    toast.success('Менеджер успешно удален')
    emit('get-contest-managers')
  } catch (e) {
    toast.error('Произошла ошибка при удалении')
  } finally {
    isLoadingDelete.value = false
  }

}

onMounted(() => {
  getAllManagers()
})

defineExpose({
  open
})
</script>
<script>
export default {
  name: "ServiceMangersDialog"
}
</script>
