<template>
  <DataTable
    ref="dt"
    v-model:filters="searchValue"
    :value="items"
    lazy
    paginator
    :rows="20"
    data-key="id"
    :total-records="props.totalCount"
    @page="handlePageChange"
  >
    <template #empty>
      <v-row justify="center">
        <v-col cols="auto">
          Данные не найдены
        </v-col>
      </v-row>
    </template>

    <template
      v-if="isUsersAndRolesPage"
      #header
    >
      <v-row justify="end">
        <v-col
          md="4"
          sm="6"
        >
          <v-text-field
            v-model="searchValue['global'].value"
            prepend-inner-icon="mdi-magnify"
            variant="outlined"
            density="compact"
            hide-details="auto"
            placeholder="Поиск по таблице"
          />
        </v-col>
      </v-row>
    </template>

    <slot />
  </DataTable>
</template>

<script setup>
import { computed, ref } from "vue";
import DataTable from "primevue/datatable";
import { useRouter } from "vue-router";
import { FilterMatchMode } from "primevue/api";

const currentRoute = useRouter();
const currentPath = currentRoute.currentRoute.value.name;

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  attributes: {
    type: Object,
    default: () => {
      return {};
    },
  },
  totalCount: {
    type: Number,
    default: () => 0,
  },
  selectedProduct3: {
    type: Array,
    default: () => [],
  }
});


const emits = defineEmits(["update-page", "update-search"]);

const handlePageChange = (page) => {
  emits("update-page", page.page + 1);
};

const isUsersAndRolesPage = computed(() => {
  return currentPath === "usersAndRoles";
});

const searchValue = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

</script>
<script>
export default {
  name: "TheTable",
};
</script>
