import { computed, ref, watch } from "vue";
import {commonValidationRules, maxFileSize} from "@/utils/common";


export function findRuleProperty(props, rul) {
  const rules = props.rules

  const isArray = rules?.every(item => Array.isArray(item))

  if (isArray) {
    return rules.find((rule) => rule.some(i => i === rul))
  }

  if (rules?.includes(rul)) {
    return rules
  }
}

export function useForm(props, emit) {
  const maxLength = computed(() => {
    if (!props.rules) {
      return false;
    }
    const item = findRuleProperty(props, 'string')

    if (!item) {
      return ""
    }

    return item[1]?.max ?? "";
  });

  const fileTypes = computed(() => {
    if (!props.rules) {
      return true
    }
    const item = findRuleProperty(props, 'file')

    if (!item) {
      return []
    }

    return item[1]?.extensions ?? [];
  });

  const fileSize = computed(() => {
    if (!props.rules) {
      return maxFileSize
    }
    const item = findRuleProperty(props, 'file')

    if (!item) {
      return maxFileSize
    }

    return item?.find(prop => prop.maxSize)?.maxSize ?? maxFileSize;
  });

  const rulesCommon = computed(() => {
    if (!props.rules) {
      return [];
    }

    return (
      props.rules.map((rule) => commonValidationRules[rule]).filter(Boolean) ??
      []
    );
  });

  // const inputModel = ref(props.default_value ? props.default_value : '');

  const inputModel = ref('');

if (props.default_value !== undefined && props.default_value !== null) {
  if (Object.prototype.hasOwnProperty.call(props.default_value, 'value')) {
    inputModel.value = props.default_value.value;
  } else if (typeof props.default_value === 'object' && Reflect.has(props.default_value, 'value')) {
    inputModel.value = props.default_value.value;
  } else {
    inputModel.value = props.default_value;
  }
} else {
  inputModel.value = props.default_value;
}

  watch(inputModel, (newValue) => {
    emit("update-value", {
      value: newValue,
      id: props.id,
    });
  });

  return { maxLength, rulesCommon, inputModel , fileTypes, fileSize };
}
