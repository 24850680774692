<template>
  <v-combobox
    :id="`inputCustom${id}`"
    ref="combobox"
    :model-value="inputModel"
    :items="options"
    item-title="name"
    item-value="value"
    :rules="rulesCommon"
    :hint="descr"
    :persistent-hint="Boolean(descr)"
    :multiple="Boolean(multiple)"
    variant="outlined"
    @update:model-value="setNewValue"
  >
    <template #item="{item: {raw}}">
      <v-list-item
        :disabled="formatPrimitiveChar(raw.disabled)"
        class="select__name"
        @click="setNewValue(raw.value)"
      >
        {{ raw.name }}
        <v-tooltip
          v-if="raw.description"
          activator="parent"
          location="top"
        >
          <div v-html="raw.description" />
        </v-tooltip>
      </v-list-item>
    </template>
  </v-combobox>
</template>

<script setup>
import { props as baseProps, emit as baseEmit } from "@/composables/form/usePropsForm";
import { useForm } from "@/composables/form/useForm";
import {ref} from "vue";
import {formatPrimitiveChar} from "@/utils/common";

const props = defineProps({
  ...baseProps
});
const emit = defineEmits(baseEmit)

const combobox = ref(null)

function setNewValue(value){
  inputModel.value = value
  combobox.value.menu = false
}

const { rulesCommon , inputModel} = useForm(props, emit);

</script>

<style scoped></style>
