<template>
  <v-row
    v-if="!isFetch"
    justify="center"
    align="center"
    class="ma-auto h-100"
  >
    <v-col cols="auto">
      <v-progress-circular
        color="primary"
        :indeterminate="true"
      />
    </v-col>
  </v-row>
  <the-container v-else>
    <template #title>
      {{ title }}
    </template>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <FormMain
          :key="formKey"
          ref="formHeader"
          :data="fieldsForm"
          @get-forms="sendForm"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto">
        <v-btn
          color="primary"
          @click="getForm"
        >
          {{ newContest ? 'Создать' : 'Сохранить' }}
        </v-btn>
      </v-col>
    </v-row>
  </the-container>
</template>

<script setup>
import TheContainer from "@/components/TheContainer";
import FormMain from "@/components/Form/FormMain";
import {ref, onMounted, computed} from "vue";
import {api} from "@/api/Api";
import {prepareFields} from "@/utils/common";
import {useRoute, useRouter} from "vue-router";
import {useToast} from "@/composables/useToast";
import {useForms} from "@/composables/forms/useForms";
import {useBasisFields} from "@/composables/fields/useBasisFields";

const route = useRoute()
const router = useRouter()
const { toast } = useToast()
const { getValuesByFields } = useForms()
const { basisContestFields } = useBasisFields()

const formKey = ref(1)
const isFetch = ref(false)
const formHeader = ref(null)
const contest = ref(null)

const fieldsForm = ref([])

const contestId = computed(() => route.params?.id ?? null )
const newContest = computed(() => !contestId.value)
const title = computed(() => contest.value ? contest.value.title : 'Новый конкурс')

async function loadItems() {
  isFetch.value = false
  const { items } = await api.contest().getContests(1, 1000)

  if (!newContest.value) {
    contest.value = await api.contest().getContest(contestId.value)
    fieldsForm.value = await getValuesByFields(contest.value, basisContestFields)
  } else {
    fieldsForm.value = basisContestFields
  }
  const basisPrent = [
    {
      name: 'Нет родительского конкурса',
      id: 0
    },
  ]
  fieldsForm.value.find(field => field.name === 'parent_id').options = [...basisPrent, ...items]

  setTimeout(() => {
    formKey.value += 1
    isFetch.value = true
  }, 1000)
}
function getForm () {
  if (formHeader.value) {
    formHeader.value.getForm()
  }
}
function sendForm (inputs, valid) {
  if (!valid) {
    return
  }

  const formData = new FormData()
  const preparedFields = prepareFields(inputs)
  const filterData = preparedFields.filter((el) => !(el.isNotSendEmptyData && !el.default_value))
  filterData.forEach(field => {
    if (field.type === 'file') {

      if (field.default_value.length === 0) {
        return
      }

      formData.append(field.name, field.default_value[0])
      return
    }

    if (!newContest.value) {
      formData.append(field.name, field.default_value)
      return;
    }

    if (field.default_value !== '') {
      formData.append(field.name, field.default_value)
    }
  })

  if (newContest.value) {
    return api.contest().createContest(formData)
      .then(({ id }) =>{
        toast.success('Новый конкурс создан')
        router.push(`/form-edit?contest_id=${id}`)
      })
      .catch((error) => {
        toast.error('Произошла ошибка при создании конкурса')
        return error
      })
  }

  api.contest().updateContest(contestId.value, formData)
    .then(({ name }) =>{
      toast.success(`Конкурс "${name}" обновлен`)
      loadItems()
    })
    .catch((error) => {
      toast.error('Произошла ошибка при обновлении конкурса')
      return error
    })
}

onMounted(() => {
  loadItems()
})
</script>

