import { provide, ref } from "vue";

export function useLoading () {
  const isShow = ref(false);

  function showLoading(value = true) {
    isShow.value = value;
  }

  provide('isShow', isShow); // Make sure this is called in the component setup

  return {
    isShow,
    showLoading,
  }
}
