<template>
  <v-dialog
    v-model="dialog"
    persistent
  >
    <v-card width="500">
      <v-card-text>
        <v-row
          v-for="input in inputs"
          :key="input.type"
          align="center"
          class="flex-column flex-sm-row"
        >
          <v-col :cols="isEdit? 4 : 6">
            {{ input.label }}
          </v-col>
          <v-col :cols="isEdit? 8 : 6">
            <span v-if="!isEdit || input.cantEdit"> {{ input.value }} </span>
            <v-text-field
              v-if="isEdit && input.type !== 'phone' && !input.cantEdit"
              v-model="changeData[input.type]"
              variant="outlined"
              hide-details="auto"
              density="compact"
            />
            <MazPhoneNumberInput
              v-if="isEdit && input.type === 'phone'"
              v-model="changeData[input.type]"
              show-code-on-list
              color="black"
              no-example
              default-country-code="RU"
              size="sm"
              :translations="{
                countrySelector: {
                  placeholder: 'Код страны',
                  error: '',
                },
                phoneInput: {
                  placeholder: '',
                  example: '',
                },
              }"
            />
          </v-col>
        </v-row>
        <v-col class="px-0 mt-4">
          <h3 class="my-6">
            Настройка уведомлений
          </h3>
          <v-row
            v-if="isLoading"
            class="px-0 justify-center align-center"
          >
            <v-progress-circular
              color="primary"
              :indeterminate="true"
            />
          </v-row>
          <template v-else>
            <div
              v-for="(field, index) in fieldsForm"
              :key="index"
            >
              <v-col class="align-center pa-0">
                <p
                  v-if="field.title"
                  class="text-body-1 ma-0 mb-2"
                >
                  {{ field.title }}
                </p>
                <Component
                  :is="fieldsInputs[getFormInput(field.type)]"
                  v-bind="field"
                  @update-value="updateValue"
                />
              </v-col>
            </div>
          </template>
        </v-col>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          v-if="!isEdit"
          color="primary"
          variant="text"
          @click="editUserInfo"
        >
          Изменить
        </v-btn>
        <v-btn
          v-if="isEdit"
          :loading="isLoading"
          color="primary"
          variant="text"
          @click="saveUserInfo"
        >
          Сохранить
        </v-btn>
        <v-btn
          v-if="!isLoading"
          color="primary"
          variant="text"
          @click="close"
        >
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import {computed, ref} from "vue";
import { useStore } from "vuex"
import {v4 as uuidv4} from "uuid";

import { api } from "@/api/Api";
import { useToast } from "@/composables/useToast";

import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import FormRadio from "@/components/Form/FormRadio";


const store = useStore()
const { toast } = useToast()

const fieldsInputs = {
  FormRadio
};

const fieldsForm = ref([
  {
    id: uuidv4(),
    group_id: 0,
    type: "radio",
    name: "send_email",
    title: "Email-уведомления",
    options: [
      {
        id: 7,
        name: "Вкл",
        value: 1,
        description: "",
        disabled: "N",
        sort: 0
      },
      {
        id: 8,
        name: "Выкл",
        value: 0,
        description: "",
        disabled: "N",
        sort: 1
      }
    ],
    default_value: 0,
    descr: "",
    note: "",
    multiple: 0,
    rules: [],
    sort: 1,
  },
  // {
  //   id: uuidv4(),
  //   group_id: 0,
  //   type: "radio",
  //   name: "send_push",
  //   title: "Push-уведомления",
  //   options: [
  //     {
  //       id: 7,
  //       name: "Вкл",
  //       value: 1,
  //       description: "",
  //       disabled: "N",
  //       sort: 0
  //     },
  //     {
  //       id: 8,
  //       name: "Выкл",
  //       value: 0,
  //       description: "",
  //       disabled: "N",
  //       sort: 1
  //     }
  //   ],
  //   default_value: 0,
  //   descr: "",
  //   note: "",
  //   multiple: 0,
  //   rules: [],
  //   sort: 1,
  // },
  // {
  //   id: uuidv4(),
  //   group_id: 0,
  //   type: "radio",
  //   name: "send_sms",
  //   title: "SMS-уведомления",
  //   options: [
  //     {
  //       id: 7,
  //       name: "Вкл",
  //       value: 1,
  //       description: "",
  //       disabled: "N",
  //       sort: 0
  //     },
  //     {
  //       id: 8,
  //       name: "Выкл",
  //       value: 0,
  //       description: "",
  //       disabled: "N",
  //       sort: 1
  //     }
  //   ],
  //   default_value: 0,
  //   descr: "",
  //   note: "",
  //   multiple: 0,
  //   rules: [],
  //   sort: 1,
  // },
])

const userInfo = computed(() => {
  return store.getters["user/userInfo"]
})

const userRole = computed(() => store.getters["user/userRoleInString"])

const inputs = computed(() => {
  return [
    {
      label: 'Фамилия',
      value: userInfo.value.userProfile.lastname,
      type: 'lastname',
      maxlength: 50,
    },
    {
      label: 'Имя',
      value: userInfo.value.userProfile.firstname,
      type: 'firstname',
      maxlength: 50,
    },
    {
      label: 'Отчество',
      value: userInfo.value.userProfile.middlename,
      type: 'middlename',
      maxlength: 50,
    },
    {
      label: 'E-mail',
      value: userInfo.value.email,
      type: 'email',
      maxlength: '',
    },
    {
      label: 'Телефон',
      value: userInfo.value.phone,
      type: 'phone',
      maxlength: 50,
    },
    {
      label: 'Организация',
      value: userInfo.value.userProfile.work_company,
      type: 'work_company',
      maxlength: 200,
    },
    {
      label: 'Регион',
      value: userInfo.value.userProfile.region,
      type: 'region',
      maxlength: 200,
    },
    {
      label: 'Населённый пункт',
      value: userInfo.value.userProfile.city,
      type: 'city',
      maxlength: 200,
    },
    {
      label: 'Роль',
      value: userRole.value,
      type: 'role',
      cantEdit: true
    }
  ]
})
const userId = computed(() => {
  return store.getters["user/currentUserId"]
})

const dialog = ref(false)
const isEdit = ref(false)
const changeData = ref({})
const isLoading = ref(false)
const settings = ref(null)

function getFormInput(type) {
  switch (type) {
    case "radio": {
      return "FormRadio";
    }
    default: {
      return "";
    }
  }
}

function open () {
  isEdit.value = false
  dialog.value = true
  getUserSetttings()
}

function close () {
  dialog.value = false
}

function toggleEditMode () {
  isEdit.value = !isEdit.value
}

async function updateUserInfo () {
  const form = new FormData()
  for (const [key, value] of Object.entries(changeData.value)) {
    if (value && key !== 'role') {
      form.append(key, value)
    }
  }
  await api.user().updateInfoByUser(userId.value, form)
}

function editUserInfo () {
  inputs.value.forEach(input => {
    changeData.value[input.type] = input.value
  })
  toggleEditMode()
}

function updateValue({value, id}) {
  fieldsForm.value.forEach((item) => {
    if (item.id === id) {
      item.default_value = value;
    }
  });
  const params = {}
  fieldsForm.value.forEach((el) => {
    params[el.name] = el.default_value
  })

  sendNewSettinsNotifications(params)
}

async function sendNewSettinsNotifications (params) {
  isLoading.value = true
  try {
    await api.notifications().changeNotificationsStatus(params)
    toast.success('Настройки уведомлений успешно обновлены')
  } catch (e) {
    toast.error('Произошла ошибка при обновлении настроек уведомлений')
  } finally {
    isLoading.value = false
  }
}

async function saveUserInfo () {
  isLoading.value = true
  try {
    await updateUserInfo()
    toast.success('Данные успешно обновлены')
    toggleEditMode()
    await store.dispatch('user/getUserInfo')
  } catch (e) {
    toast.error('Произошла ошибка при обновлении данных')
  } finally {
    isLoading.value = false
  }
}

function setDefaultValueSettings () {
  fieldsForm.value = fieldsForm.value.map((el) => {
    const newVal = settings.value ? +settings.value[el.name] : 0
    el.default_value = newVal
    return el
  })
}

async function getUserSetttings() {
  isLoading.value = true

  try {
    settings.value = await  api.notifications().getSettingsNotifications()
    setDefaultValueSettings()
  } catch (e) {
    toast.error('Произошла ошибка при получении данных')
  } finally {
    isLoading.value = false
  }
}

defineExpose({
  open
})
</script>

<style>
.m-input-wrapper.--default-border {
  border-color: #ababab !important;
  border-width: 1px;
}
.m-phone-number-input__select > .maz-rounded-lg  {
  border-radius: 4px;
}
.m-phone-number-input__input> .maz-rounded-lg  {
  border-radius: 4px;
}
</style>
