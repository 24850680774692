<template>
  <div>
    <header class="header">
      <SvgBase
        width="129"
        height="35"
      >
        <SvgLogo />
      </SvgBase>
    </header>
    <main class="main">
      <div class="main__form">
        <slot />
      </div>
    </main>
  </div>
</template>

<script>
import SvgBase from "@/components/Svg/SvgBase";
import SvgLogo from "@/components/Svg/SvgLogo";
export default {
  name: "AuthLayout",
  components: {SvgLogo, SvgBase},
};
</script>

<style lang="scss" scoped>
.main {
  margin: 0 auto;
  display: block;
  padding-bottom: 80px;

  &__form {
    text-align: center;
  }

  @media (max-width: 599.95px) {
    padding-bottom: 20px;
  }
}

.header {
  display: flex;
  padding-top: 40px;
  padding-bottom: 56px;
  justify-content: center;

  &__logo {
    cursor: pointer;
  }

  @media (max-width: 599.95px) {
    padding-top: 20px;
    padding-bottom: 20px;
    &__logo {
      height: 20px;
    }
  }
}
</style>
