const state = {
    contest: null
};

const getters = {
    contest: (state) => state.contest
};

const actions = {
    async setContest({ commit }, contest){
        commit('setContest', contest)
    }
};

const mutations = {
    setContest(state, value) {
        state.contest = value
    },
};

export const contest = {
    namespaced:
        true,
    state,
    getters,
    actions,
    mutations,
};
