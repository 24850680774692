<template>
  <template v-if="isManager || isExpert">
    <div class="font-weight-bold mt-6 ml-2">
      Выгрузить анкеты
    </div>

    <div class="d-flex flex-row mb-2">
      <v-sheet
        class="pa-2 flex-grow-1"
        style="flex-basis: 0; flex-grow: 1; background: rgba(255, 255, 255, 0)"
      >
        <div class="flex-grow-0 text-no-wrap">
          Форма анкеты
        </div>
        <v-select
          v-model="selectedForm"
          :items="forms"
          return-object
          item-title="title"
          item-value="id"
          variant="outlined"
          hide-details="auto"
          clearable
          @change="filterResult"
        />
      </v-sheet>
      <v-sheet
        class="pa-2 flex-grow-1"
        style="flex-basis: 0; flex-grow: 1; background: rgba(255, 255, 255, 0)"
      >
        <div class="flex-grow-0 text-no-wrap">
          Статус анкеты
        </div>
        <v-select
          v-model="selectFilterStatus"
          :items="filterStatuses"
          return-object
          item-title="name"
          item-value="value"
          variant="outlined"
          hide-details="auto"
          clearable
        />
      </v-sheet>
      <v-sheet
        class="pa-2 flex-grow-1 centered-input"
        style="flex-basis: 0; flex-grow: 1; background: rgba(255, 255, 255, 0)"
      >
        <div class="flex-grow-0 labelTitle">
          Название организации или команды
        </div>
        <v-text-field
          v-model="searchFieldValue"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          density="compact"
          hide-details="auto"
          clearable
          class="centered-input"
          @keydown.enter="updateSearch"
        />
      </v-sheet>
    </div>
    <div class="d-flex flex-row mb-2">
      <v-sheet
        class="pa-2 flex-grow-1"
        style="flex-basis: 0; flex-grow: 1; background: rgba(255, 255, 255, 0)"
      >
        <div class="flex-grow-0 text-no-wrap">
          Пользователь
        </div>
        <v-select
          v-model="selectedUser"
          :items="uniqueUsers"
          return-object
          item-title="name"
          item-value="id"
          variant="outlined"
          hide-details="auto"
          clearable
          :disabled="uniqueUsers.length === 0 && !selectedUser"
          :style="{
            backgroundColor: uniqueUsers.length === 0 ? '#e4e4e4' : 'inherit',
          }"
        />
      </v-sheet>
      <v-sheet
        class="pa-2 flex-grow-1"
        style="flex-basis: 0; flex-grow: 1; background: rgba(255, 255, 255, 0)"
      >
        <div class="flex-grow-0 text-no-wrap">
          Дата от
        </div>
        <div class="datepicker-container">
          <Datepicker
            v-model="selectedStartDate"
            class="datepicker-border"
            :clearable="true"
          >
            <template #clear="{ onClear }">
              <button
                style="color: #777777"
                @click="onClear"
              >
                <v-icon icon="fas mdi-close-circle" />
              </button>
            </template>
          </Datepicker>
        </div>
      </v-sheet>
      <v-sheet
        class="pa-2 flex-grow-1"
        style="flex-basis: 0; flex-grow: 1; background: rgba(255, 255, 255, 0)"
      >
        <div class="flex-grow-0 text-no-wrap">
          Дата до
        </div>
        <div class="datepicker-container">
          <Datepicker
            v-model="selectedEndDate"
            class="datepicker-border"
            :clearable="true"
          >
            <template #clear="{ onClear }">
              <button
                style="color: #777777"
                @click="onClear"
              >
                <v-icon icon="fas mdi-close-circle" />
              </button>
            </template>
          </Datepicker>
        </div>
      </v-sheet>
    </div>

    <div class="d-flex flex-row mb-2">
      <v-sheet
        class="pa-2 flex-grow-1"
        style="flex-basis: 0; flex-grow: 1; background: rgba(255, 255, 255, 0)"
      >
        <div class="flex-grow-0 text-no-wrap">
          Тип выгрузки:
        </div>
        <v-select
          v-model="selectedTypeExport"
          :items="typesExport"
          return-object
          item-title="title"
          item-value="id"
          variant="outlined"
          hide-details="auto"
          clearable
        />
      </v-sheet>
      <v-sheet
        class="pa-2 flex-grow-1"
        style="flex-basis: 0; flex-grow: 1; background: rgba(255, 255, 255, 0)"
      >
        <template v-if="fields.length">
          <div class="flex-grow-0 text-no-wrap">
            Список полей:
          </div>
          <v-combobox
            v-model="selectedFields"
            :items="fields"
            return-object
            item-title="title"
            item-value="name"
            variant="outlined"
            hide-details="auto"
            multiple
          />
        </template>
      </v-sheet>
      <v-sheet
        class="pa-2 flex-grow-1"
        style="flex-basis: 0; flex-grow: 1; background: rgba(255, 255, 255, 0)"
      >
        <v-checkbox
          v-model="isDraftExport"
          hide-details
          class="mb-0 pb-0 mt-4"
          label="Выгрузить анкеты со статусом 'Черновик'"
        />
      </v-sheet>
    </div>

    <div class="d-flex flex-row mb-2">
      <v-sheet
        class="pa-2 flex-grow-1"
        style="flex-basis: 0; flex-grow: 1; background: rgba(255, 255, 255, 0)"
      >
        <v-btn
          :disabled="!selectedForm"
          color="primary"
          variant="text"
          class="mt-6 px-0"
          @click="exportResult"
        >
          Выгрузить в .xls
        </v-btn>
      </v-sheet>
      <v-sheet
        class="pa-2 flex-grow-1"
        style="flex-basis: 0; flex-grow: 1; background: rgba(255, 255, 255, 0)"
      />
    </div>
  </template>
  <TheLoading v-if="isLoading" />

  <TheTable
    v-else
    v-model:selection="selectedProduct3"
    :items="items"
    :attributes="{
      sortField: 'date_create',
      sortOrder: -1,
      dataKey: 'id',
    }"
    :total-count="props.totalCount"
    @update-page="updatePage"
    @update-search="updateSearch"
  >
    <Column
      v-if="isManager"
      selection-mode="multiple"
    />
    <Column
      field="id"
      header="ID-анкеты"
      :sortable="true"
    >
      <template #body="{ data: { id: id } }">
        <router-link :to="`/questionnaire/${id}`">
          {{ id }}
        </router-link>
      </template>
    </Column>
    <Column
      field="date_submit"
      header="Дата и время подачи"
      :sortable="true"
    />
    <Column
      field="forma.title"
      header="Название формы анкеты"
      :sortable="true"
    />
    <Column
      field="org_name_value"
      header="Название организации"
      :sortable="true"
    />
    <Column
      field="command_name_value"
      header="Название команды"
      :sortable="true"
    />
    <Column
      field="contest_status.status"
      header="Статус заявки"
      :sortable="true"
    />
    <Column
      field="status"
      header="Статус анкеты"
      :sortable="true"
    >
      <template #body="{ data: { status }, data }">
        <span v-if="!hasSetStatus(status)">
          {{ defaultStatus[status] }}
        </span>
        <v-select
          v-if="hasSetStatus(status)"
          :items="statusesCurrent"
          :model-value="defaultStatus[status]"
          item-title="name"
          item-value="value"
          variant="underlined"
          hide-details="auto"
          @update:model-value="openDialog($event, data)"
        />
      </template>
    </Column>
  </TheTable>
  <v-row
    v-if="isManager"
    class="mt-1"
  >
    <v-col cols="3">
      <p>Изменить статус анкет(ы) на:</p>
    </v-col>
    <v-col cols="2">
      <v-select
        v-model="allSelectedStatus"
        :items="statusesCurrent"
        item-title="name"
        item-value="value"
        variant="underlined"
        hide-details="auto"
      />
    </v-col>
    <v-col
      cols="auto"
      class="align-self-center"
    >
      <v-btn
        color="primary"
        variant="text"
        :disabled="!isQuestionnaireSelected || !allSelectedStatus"
        @click="handleChangeAll"
      >
        Изменить
      </v-btn>
    </v-col>
  </v-row>

  <v-dialog v-model="isModalWithCommentOpen">
    <v-card>
      <v-card-title>
        Добавить единый комментарий (причину) для всех анкет.
      </v-card-title>
      <v-card-text>
        <v-textarea
          v-model="modalTextCommon"
          rows="5"
          color="primary"
          variant="outlined"
          class="text-pre-wrap"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          :disabled="!modalTextCommon?.trim()"
          @click="setMultiStatus"
        >
          Изменить
        </v-btn>
        <v-btn @click="closeModal">
          Отменить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="isShowDialog">
    <v-card>
      <v-card-text>
        Установить анкете статус - "{{ defaultStatus[selectedStatus.status] }}"
      </v-card-text>
      <v-card-text v-if="selectedStatus.status !== 'accept'">
        Добавьте комментарий
      </v-card-text>
      <v-card-text v-if="selectedStatus.status !== 'accept'">
        <v-textarea
          v-model="modalTextPrivate"
          rows="5"
          color="primary"
          variant="outlined"
          class="text-pre-wrap"
        />
      </v-card-text>
      <v-card-actions>
        <v-row
          :no-gutters="true"
          justify="center"
        >
          <v-col cols="auto">
            <v-btn
              :disabled="selectedStatus.status !== 'accept' && !modalTextPrivate"
              @click="setCurrentStatus"
            >
              Да
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn @click="closeDialog">
              Нет
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import TheTable from "@/components/TheTable";
import Column from "primevue/column";
import { computed, onMounted, ref, watch } from "vue";
import { defaultStatus } from "@/utils/common";
import { useStore } from "vuex";
import { api } from "@/api/Api";
import { useToast } from "@/composables/useToast";
import { DRAFT_STATUS } from "@/utils/constants";
import { formatDate } from "@/utils/common";
import Datepicker from "vue3-datepicker";
import TheLoading from "@/components/TheLoading";

const store = useStore();
const { toast } = useToast();

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  hasSelection: {
    type: Boolean,
    default: false,
  },
  formFields: {
    type: Array,
    default: () => [],
  },
  totalCount: {
    type: Number,
    default: () => 0,
  },
  uniqueOrgNames: {
    type: Array,
    default: () => [],
  },
  uniqueUsers: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits([
  "update-questionnaire",
  "filter-result",
  "filter-questionnaire",
  "update-page",
  "update-search",
  "filter-questionnaire-id",
  "filter-questionnaire-user",
  "filter-questionnaire-type",
  "filter-questionnaire-start-date",
  "filter-questionnaire-end-date",
]);

const updatePage = (page) => {
  emit("update-page", page);
};

const updateSearch = (e) => {
  emit("update-search", e.target.value);
};

const filterStatuses = ref([
  {
    value: "draft",
    name: "Черновик",
  },
  {
    value: "sent",
    name: "Повторная проверка",
  },
  {
    value: "revision",
    name: "Требует доработки",
  },
  {
    value: "accept",
    name: "Анкета одобрена",
  },
  {
    value: "reject",
    name: "Анкета отклонена",
  },
  {
    value: "new",
    name: "На рассмотрении",
  },
]);

const selectFilterStatus = ref(null);
const selectedFilterQuestionnaireId = ref(null);
const selectedUser = ref(null);
const selectedStartDate = ref(null);
const selectedEndDate = ref(null);
const searchFieldValue = ref(null);
const isLoading = ref(true); // Переменная для отображения лоадера

const modalTextCommon = ref(null);
const modalTextPrivate = ref(null);

const statuses = ref([
  {
    value: "accept",
    name: "Одобрить",
  },
  {
    value: "reject",
    name: "Отклонить",
  },
]);
const statusesCurrent = ref([
  ...statuses.value,
  {
    value: "revision",
    name: "На доработку",
  },
]);

const typesExport = ref([
  {
    id: 0,
    title: "Горизонтальная",
  },
  {
    id: 1,
    title: "Вертикальная",
  },
]);

const filteredSelectedItems = ref([]);
const allSelectedStatus = ref(null);
const isShowDialog = ref(false);
const selectedStatus = ref({});
const selectedForm = ref(null);
const selectedTypeExport = ref(typesExport.value[1]);
const isDraftExport = ref(false);
const selectedFields = ref(null);
const forms = ref([]);
const selectedProduct3 = computed({
  get() {
    return filteredSelectedItems.value;
  },
  set(value) {
    filteredSelectedItems.value = value;
  },
});

const isManager = computed(() => store.getters["user/isManager"]);
const isExpert = computed(() => store.getters["user/isExpert"]);

const isQuestionnaireSelected = ref(false);

const isModalWithCommentOpen = ref(false);

const fields = computed(() => {
  return props.formFields;
});

async function getForms() {
  try {
    await api
      .form()
      .getForms(1, 1000, { exclude: "fields" })
      .then((data) => {
        forms.value = data?.items?.map((el) => {
          return {
            ...el,
            title: (el.title || "") + " - " + (el?.contest?.name || ""),
          };
        });
      });
  } catch (err) {
    console.log(err);
  } finally {
    isLoading.value = false; // Скрыть лоадер после загрузки данных
  }
}

function hasSetStatus(status) {
  if (!isManager.value) {
    return false;
  }

  return (
    defaultStatus.new === defaultStatus[status] ||
    defaultStatus.sent === defaultStatus[status] ||
    defaultStatus.accept === defaultStatus[status]
  );
}

async function sendStatus(sendItems, status, reason) {
  const promises = sendItems.map((item) => {
    return api.result().setStatus(item.id, status, reason);
  });

  return await Promise.all(promises).then((response) => {
    response.forEach((message) => {
      toast.success(message);
    });
    emit("update-questionnaire");
  });
}

async function setMultiStatus() {
  if (filteredSelectedItems.value.length === 0) {
    return;
  }

  if (!allSelectedStatus.value) {
    return;
  }

  await sendStatus(filteredSelectedItems.value, allSelectedStatus.value, modalTextCommon.value);
  modalTextCommon.value = '';
  filteredSelectedItems.value = [];
  allSelectedStatus.value = null;

  closeModal();
}

function openDialog(status, id) {
  selectedStatus.value = { id, status };
  isShowDialog.value = true;
}
function openModal() {
  isModalWithCommentOpen.value = true;
}

function handleChangeAll() {
  if (allSelectedStatus.value === "accept") {
    setMultiStatus();
  } else {
    openModal();
  }
}
function closeDialog() {
  isShowDialog.value = false;
  selectedStatus.value = {};
  modalTextPrivate.value = '';
}
function closeModal() {
  isModalWithCommentOpen.value = false;
  modalTextCommon.value = "";
}
function setCurrentStatus() {
  if (selectedStatus.value.status !== "accept") {
    sendStatus([selectedStatus.value?.id], selectedStatus.value?.status, modalTextPrivate.value);
  } else {
    sendStatus([selectedStatus.value?.id], selectedStatus.value?.status);
  }
  modalTextPrivate.value = ''
  closeDialog();
}
function filterResult() {
  emit("filter-result", selectedForm.value?.id);
  fields.value = [];
  selectedFields.value = null;
}

watch(selectedForm, () => {
  filterResult();
});

watch(fields, () => {
  selectedFields.value = fields.value;
});

watch(selectFilterStatus, () => {
  emit("filter-questionnaire", selectFilterStatus.value?.value);
});

watch(searchFieldValue, (value) => {
  emit("update-search", value);
});

watch(selectedFilterQuestionnaireId, () => {
  emit("filter-questionnaire-id", selectedFilterQuestionnaireId.value?.id);
});

watch(selectedUser, () => {
  emit("filter-questionnaire-user", selectedUser.value?.id);
});

watch(selectedStartDate, () => {
  emit("filter-questionnaire-start-date", formatDate(selectedStartDate.value));
});

watch(selectedEndDate, () => {
  emit("filter-questionnaire-end-date", formatDate(selectedEndDate.value));
});

watch(selectedProduct3, () => {
  if (selectedProduct3.value?.length) {
    isQuestionnaireSelected.value = true;
  } else {
    isQuestionnaireSelected.value = false;
  }
});

function exportResult() {
  const filteredItems = filteredSelectedItems?.value?.map((el) => el?.id) || [];
  let fieldsNames = selectedFields?.value?.map((el) => el.name) || [];

  fields?.value?.forEach((el) => {
    if (el.type === "group") {
      const id = el.id;
      const findGroup = selectedFields?.value?.filter(
        (field) => field.group_id === id
      );

      if (findGroup?.length && !fieldsNames.includes(el.name)) {
        fieldsNames.push(el.name);
      }
    }
  });

  selectedFields?.value?.forEach((el) => {
    if (el.type === "group") {
      const filterFieldsGroup = fields?.value?.filter(
        (field) => field.group_id === el.id
      );
      filterFieldsGroup?.forEach((fieldField) => {
        if (!fieldsNames.includes(fieldField.name)) {
          fieldsNames.push(fieldField.name);
        }
      });
    }
  });

  const form = new FormData();
  form.append("direction", selectedTypeExport?.value?.id || "");
  selectedForm?.value?.id && form.append("id", selectedForm.value.id);
  selectFilterStatus?.value?.value &&
    form.append("status", selectFilterStatus.value.value);
  selectedUser?.value?.id && form.append("user_id", selectedUser.value.id);
  selectedStartDate?.value &&
    form.append("date_from", String(formatDate(selectedStartDate.value)));
  selectedStartDate?.value &&
    form.append("date_to", String(formatDate(selectedStartDate.value)));
  searchFieldValue?.value && form.append("q", searchFieldValue.value);

  filteredItems?.forEach((el) => {
    form.append("results[]", el);
  });

  if (fieldsNames.length !== (fields?.value?.length || 0)) {
    fieldsNames?.forEach((el) => {
      form.append("fields[]", el);
    });
  }

  const isDraftSelected = selectFilterStatus.value?.value === "draft";


  if (isDraftExport?.value) {
    form.append("status", DRAFT_STATUS);
  }

  const fileNameSuffix = (isDraftExport?.value || isDraftSelected) ? "_черновики" : ""; 

  api
    .result()
    .exportResultByFormId(form)
    .then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );

      let filename = selectedForm?.value?.title || "exported_file";
      filename = filename.replace(/\./g, "_") + fileNameSuffix;
      const link = document.createElement("a");
      link.href = url;
      link.download = filename.replaceAll(".", "_");
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch(() => {
      toast.error("Не удалось сформировать файл");
    });
}

onMounted(() => {
  getForms();
});
</script>

<script>
export default {
  name: "QuestionnaireTable",
  components: {
    TheLoading
  },
  data() {
    return {
      date: new Date(2016, 9, 16),
    };
  },
};
</script>

<style lang="scss">
.v3dp__datepicker {
  border: 1px solid #a7a7a7;
  border-radius: 4px;
  height: 56px;
  width: 100%;
}

.datepicker-container {
  .datepicker-border {
    border-radius: 4px;
    height: 56px;
    width: 100%;
    padding-left: 15px;
  }

  .datepicker-border:focus-visible {
    border: none !important;
    outline: none !important;
  }
}

.centered-input {
  display: grid;

  .v-input__control {
    .v-field__prepend-inner {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 0;
    }

    input {
      height: 56px;
    }
  }

  .v-field__clearable {
    .v-icon--clickable {
      margin-top: 8px;
    }
  }
}

.v3dp__input_wrapper {
  display: flex;

  .v3dp__clearable {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.v-field__input {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.labelTitle {
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
