<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="111"
    height="31"
    viewBox="0 0 111 31"
    fill="none"
  >
    <path
      d="M24.6669 2.17969H30.229C32.1636 2.17969 33.7355 3.75391 33.7355 5.69141V24.9453C33.7355 26.7617 32.1636 28.3359 30.3499 28.3359H24.7878C22.8531 28.3359 21.2812 26.7617 21.2812 24.8242V5.57031C21.2812 3.75391 22.7322 2.17969 24.6669 2.17969ZM24.6669 25.1875H30.229V5.32813H24.6669V25.1875Z"
      fill="#BDBECA"
    />
    <path
      d="M3.26471 2.17969H16.0817C17.8954 2.17969 19.3464 3.63281 19.3464 5.44922V24.9453C19.3464 26.7617 17.8954 28.2148 16.0817 28.2148H3.26471C1.45098 28.2148 0 26.7617 0 24.9453V5.44922C0 3.63281 1.45098 2.17969 3.26471 2.17969ZM3.50654 25.1875H15.9608V5.32813H3.50654V25.1875Z"
      fill="#BDBECA"
    />
    <path
      d="M39.0554 28.3359H35.5488V2.17969H39.0554V13.0781H44.6175V2.17969H48.124V28.3359H44.6175V16.2266H39.0554V28.3359Z"
      fill="#BDBECA"
    />
    <path
      d="M51.5097 31H49.2124L48.8496 25.1875H50.7842L53.4444 5.20703H52.3561L51.6307 2.05859H59.9738C61.5457 2.05859 62.8758 3.26953 62.8758 4.84375L64.2058 25.0664H66.1405L65.5359 30.8789H62.9967L62.3921 28.2148H52.1143L51.5097 31ZM54.049 25.1875H60.8202L59.8529 5.32812H56.7091C56.1045 9.44531 54.049 25.1875 54.049 25.1875Z"
      fill="#BDBECA"
    />
    <path
      d="M76.6599 28.3359H73.1533V2.17969H76.6599V13.0781H82.2219V2.17969H85.7285V28.3359H82.2219V16.2266H76.6599V28.3359Z"
      fill="#BDBECA"
    />
    <path
      d="M87.1797 2.17969H96.9738V5.32813H94.0718V28.457H90.5653V5.32813H87.1797V2.17969Z"
      fill="#BDBECA"
    />
    <path
      d="M107.493 10.7773L101.931 28.3359H98.4248V2.17969H101.931V19.8594L107.493 2.17969H111V28.3359H107.493V10.7773Z"
      fill="#BDBECA"
    />
    <path
      d="M11.5002 31H7.99365L8.00019 1H11.5067L11.5002 31Z"
      fill="#BDBECA"
    />
  </svg>
</template>
