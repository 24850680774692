<template>
  <template v-if="isManager || isExpert">
    <div class="font-weight-bold mt-6 ml-2 mb-6">
      Выгрузить анкеты
    </div>

    <div class="d-flex flex-row mb-2">
      <v-sheet
        class="pa-2 flex-grow-1"
        style="flex-basis: 0; flex-grow: 1; background: rgba(255, 255, 255, 0)"
      >
        <div class="flex-grow-0 text-no-wrap">
          Группы
        </div>
        <v-select
          v-model="selectedGroup"
          :items="groupList"
          return-object
          item-title="name"
          item-value="id"
          variant="outlined"
          hide-details="auto"
          clearable
        />
      </v-sheet>
      <v-sheet
        class="pa-2 flex-grow-1"
        style="flex-basis: 0; flex-grow: 1; background: rgba(255, 255, 255, 0)"
      >
        <div class="flex-grow-0 text-no-wrap">
          E-Mail
        </div>
        <v-text-field
          v-model="searchFieldValueMail"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          density="compact"
          hide-details="auto"
          clearable
          class="centered-input"
        />
      </v-sheet>
      <v-sheet
        class="pa-2 flex-grow-1 centered-input"
        style="flex-basis: 0; flex-grow: 1; background: rgba(255, 255, 255, 0)"
      >
        <div class="flex-grow-0 labelTitle">
          Телефон
        </div>
        <v-text-field
          v-model="searchFieldValuePhone"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          density="compact"
          hide-details="auto"
          clearable
          class="centered-input"
        />
      </v-sheet>
    </div>
    <div class="d-flex flex-row mb-12">
      <v-sheet
        class="pa-2 flex-grow-1 centered-input"
        style="flex-basis: 0; flex-grow: 1; background: rgba(255, 255, 255, 0)"
      >
        <div class="flex-grow-0 labelTitle">
          Город
        </div>
        <v-text-field
          v-model="searchFieldValueCity"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          density="compact"
          hide-details="auto"
          clearable
          class="centered-input"
        />
      </v-sheet>
      <v-sheet
        class="pa-2 flex-grow-1 centered-input"
        style="flex-basis: 0; flex-grow: 1; background: rgba(255, 255, 255, 0)"
      >
        <div class="flex-grow-0 labelTitle">
          Название компании
        </div>
        <v-text-field
          v-model="searchFieldValueCompanyName"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          density="compact"
          hide-details="auto"
          clearable
          class="centered-input"
        />
      </v-sheet>
      <v-sheet
        class="pa-2 flex-grow-1 centered-input"
        style="flex-basis: 0; flex-grow: 1; background: rgba(255, 255, 255, 0)"
      >
        <div class="flex-grow-0 labelTitle">
          Имя
        </div>
        <v-text-field
          v-model="searchFieldValueUserName"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          density="compact"
          hide-details="auto"
          clearable
          class="centered-input"
        />
      </v-sheet>
    </div>
  </template>

  <TheTable
    :items="users"
    :total-count="totalCount"
    @update-page="updatePage"
  >
    <Column
      field="id"
      header="ID"
      :sortable="true"
    />
    <Column
      field="fio"
      header="ФИО"
      :sortable="true"
      class="text-break"
    />
    <Column
      field="email"
      header="E-Mail"
      :sortable="true"
    />
    <Column
      field="phone"
      header="Телефон"
      :sortable="true"
    />
    <Column
      field="groups"
      header="Группы"
    >
      <template #body="{ data: { groups } }">
        <span>{{ setGroups(groups) }}</span>
      </template>
    </Column>
    <Column field="addGroups">
      <template #body="{ data: { id, groups } }">
        <v-btn
          variant="text"
          color="primary"
          class="text-caption"
          @click.prevent="addGroup(id, groups)"
        >
          Назначить группу
        </v-btn>
      </template>
    </Column>
  </TheTable>
  <UsersAddGroupDialog
    ref="addGroupDialog"
    :user-id="selectedUserId"
    :user-groups="selectedGroups"
    :page="tablePage"
    @on-close="resetFormGroups"
  />
</template>

<script setup>
import { ref, watch, computed } from "vue";
import TheTable from "@/components/TheTable";
import Column from "primevue/column";
import { replaceRole } from "@/utils/common";
import UsersAddGroupDialog from "@/components/Users/UsersAddGroupDialog";
import { useStore } from "vuex";

defineProps({
  users: {
    type: Array,
    default: () => [],
  },
  totalCount: {
    type: Number,
    default: () => 0,
  },
  uniqueUsers: {
    type: Array,
    default: () => [],
  },
  groupList: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(["update-page", "update-search"]);
const tablePage = ref(null);

const updatePage = (page) => {
  emit("update-page", page);
  tablePage.value = page;
};

const addGroupDialog = ref(null);
const selectedUserId = ref(null);
const selectedGroups = ref([]);
const store = useStore();
const selectedUser = ref(null);
const selectedGroup = ref(null);

const searchFieldValueMail = ref(null);
const searchFieldValuePhone = ref(null);
const searchFieldValueCity = ref(null);
const searchFieldValueCompanyName = ref(null);
const searchFieldValueUserName = ref(null);

const isManager = computed(() => store.getters["user/isManager"]);
const isExpert = computed(() => store.getters["user/isExpert"]);

function concatGroup(groups) {
  return groups.map((group) => replaceRole(group.group_id)).join(", ");
}

function setGroups(groups) {
  return groups.length === 0 ? "нет группы" : concatGroup(groups);
}

function addGroup(userId, groups) {
  if (addGroupDialog.value) {
    selectedUserId.value = userId;
    selectedGroups.value = groups;
    addGroupDialog.value.open();
  }
}

function resetFormGroups() {
  selectedUserId.value = null;
  selectedGroups.value = [];
}

watch(selectedUser, () => {
  const userIdVal = selectedUser.value?.id;
  if (userIdVal !== null && userIdVal !== undefined) {
    store.dispatch("user/filterQuestionnaireUser", userIdVal);
  }
});

watch(selectedGroup, () => {
  emit("update-search", "group_id", selectedGroup.value?.id);
});

watch(searchFieldValueMail, (value) => {
  emit("update-search", "email", value);
});
watch(searchFieldValuePhone, (value) => {
  emit("update-search", "phone", value);
});
watch(searchFieldValueCity, (value) => {
  emit("update-search", "city", value);
});
watch(searchFieldValueCompanyName, (value) => {
  emit("update-search", "work_company", value);
});
watch(searchFieldValueUserName, (value) => {
  emit("update-search", "name", value);
});
</script>

<script>
export default {
  name: "UsersTable",
};
</script>
