import { createRouter, createWebHistory } from 'vue-router'
import ServiceView from '../views/Service/ServiceView.vue'
import ServiceIdView from '../views/Service/ServiceIdView.vue'
import ServiceFormView from '../views/Service/ServiceFormView.vue'
import CallbackView from '../views/CallbackView.vue'
import LoginView from '../views/LoginView.vue'
import DefaultLayout from '../layouts/default.vue'
import { tokenService } from "@/services/token";
import UsersAndRolesView from '../views/UsersAndRolesView.vue'
import store from "@/store";
// import MyOrdersView from '../views/MyOrdersView.vue'
import ServiceEditView from '../views/Service/ServiceEditView.vue'
import OrdersView from '../views/OrdersView'
import QuestionnairesView from '../views/Questionnaire/QuestionnairesView.vue'
import QuestionnaireView from '../views/Questionnaire/QuestionnaireView.vue'
import FormsView from '../views/Form/FormsView.vue'
import FormEditView from '../views/Form/FormEditView.vue'
import {
  ROUTE_PAGE_APPLICATION_FOR_MANAGER,
  ROUTE_PAGE_APPLICATION_FOR_USER,
  ROUTE_OPEN_HISTORY_APPLICATION_FOR_USER
} from "@/constants";


const routes = [
  {
    path: '/selections',
    name: 'Selections',
    meta: {
      privatePage: true,
    },
    component: DefaultLayout,
    children: [
      {
        path: '/selections',
        name: 'selections',
        component: ServiceView,
        meta: {
          privatePage: true,
          canUser: true,
          isSelections: true,
        },
      },
      {
        path: '/selections/:id',
        name: 'selectionsServiceId',
        component: ServiceIdView,
        props: true,
        meta: {
          privatePage: true,
          canUser: true,
          isSelections: true
        },
      },
      {
        path: '/selections/form/:id',
        name: 'selectionsFormId',
        component: ServiceFormView,
        props: true,
        meta: {
          privatePage: true,
          canUser: true,
          isSelections: true
        },
      },
      {
        path: '/selections/my-orders',
        name: 'selectionsMyOrders',
        component: OrdersView,
        meta: {
          privatePage: true,
          canUser: true,
          isSelections: true
        },
      },
      {
        path: '/selections/questionnaire/:id',
        name: 'selectionsQuestionnaireId',
        component: QuestionnaireView,
        meta: {
          privatePage: true,
          canUser: true,
          isSelections: true
        },
      },
    ]
  },
  {
    path: '/',
    name: 'Default',
    redirect: '/service',
    meta: {
      privatePage: true,
      canAdminCompetition: true,
      canExpert: true,
    },
    component: DefaultLayout,
    children: [
      {
        path: 'service',
        name: 'service',
        component: ServiceView,
        meta: {
          privatePage: true,
        },
      },
      {
        path: 'service-edit',
        name: 'serviceEdit',
        component: ServiceEditView,
        meta: {
          privatePage: true,
          canAdmin: true,
        },
      },
      {
        path: 'service-edit/:id',
        name: 'serviceEditId',
        component: ServiceEditView,
        meta: {
          privatePage: true,
          canAdmin: true,
        },
      },
      {
        path: 'service/:id',
        name: 'serviceId',
        component: ServiceIdView,
        props: true,
        meta: {
          privatePage: true,
          canUser: true,
        },
      },
      {
        path: 'form/:id',
        name: 'formId',
        component: ServiceFormView,
        props: true,
        meta: {
          privatePage: true,
          canUser: true,
          canAdmin: true,
        },
      },
      {
        path: 'users-and-roles',
        name: 'usersAndRoles',
        component: UsersAndRolesView,
        meta: {
          privatePage: true,
          canAdmin: true,
          canAdminCompetition: false
        },
      },
      {
        path: 'my-orders',
        name: 'myOrders',
        component: OrdersView,
        meta: {
          privatePage: true,
          canUser: true,
        },
      },
      {
        path: 'orders',
        name: 'orders',
        component: OrdersView,
        meta: {
          privatePage: true,
          canManager: true,
          canExpert: true,
        },
      },
      {
        path: 'questionnaires',
        name: 'questionnaires',
        component: QuestionnairesView,
        meta: {
          privatePage: true,
          canManager: true,
          canExpert: true,
        },
      },
      {
        path: 'questionnaire/:id',
        name: 'questionnaireId',
        component: QuestionnaireView,
        meta: {
          privatePage: true,
          canManager: true,
          canUser: true,
          canExpert: true,
        },
      },
      {
        path: 'forms',
        name: 'forms',
        component: FormsView,
        meta: {
          privatePage: true,
          canAdmin: true,
        },
      },
      {
        path: 'form-edit',
        name: 'formEdit',
        component: FormEditView,
        props: true,
        meta: {
          privatePage: true,
          canAdmin: true,
        },
      },
      {
        path: 'form-edit/:id',
        name: 'formEditId',
        component: FormEditView,
        props: true,
        meta: {
          privatePage: true,
          canAdmin: true,
        },
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      privatePage: false
    },
  },
  {
    path: '/callback',
    name: 'callback',
    component: CallbackView,
    meta: {
      privatePage: false
    },
  },
  {
    path: '/personal-account-v2-auth',
    name: 'personal-account-v2-auth',
    component: CallbackView,
    props: true,
    meta: {
      privatePage: true,
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.name === 'formId') {
    const fullPath = to.fullPath
    localStorage.setItem('entryPath', fullPath)
  }

  const { privatePage , canAdmin , canManager , canUser, canAdminCompetition, canExpert } = to.meta
  const isLoggedIn = Boolean(tokenService.getToken())

  if (to.fullPath.includes(process.env.VUE_APP_REDIRECT_LOGOUT_KEY)) {
    localStorage.setItem(process.env.VUE_APP_REDIRECT_KEY_LK, 'true')
    store.dispatch('auth/logout')
    router.push('/login')
    return;
  }

  if (privatePage && to.fullPath.includes(process.env.VUE_APP_REDIRECT_KEY_LK)) {
    await store.dispatch('user/getUserInfo').then(() => {
      if (isLoggedIn) {
        window.location.href = `${process.env.VUE_APP_LK2_ROUTE}${process.env.VUE_APP_REDIRECT_KEY_LK}?token=${tokenService.getToken()}`
      }
    }).catch(() => {
      localStorage.setItem(process.env.VUE_APP_REDIRECT_KEY_LK, 'true')
      store.dispatch('auth/logout')
      router.push('/login')
    })
  }

  if (privatePage && !isLoggedIn) {
    localStorage.setItem('redirectRoute', to.fullPath)
    next({name: 'login'})
    return
  }

  if(!privatePage && isLoggedIn) {
    next('/')
  }

  if (!store.getters["user/currentUserId"] && isLoggedIn) {
    await store.dispatch('user/getUserInfo')
  }

  const isAdmin = store.getters["user/isAdmin"]
  const isManager = store.getters["user/isManager"]
  const isExpert = store.getters["user/isExpert"]
  const isUser = store.getters["user/isUser"]
  const isAdminCompetiton = store.getters["user/isAdminCompetition"]

  if (!isUser && to.fullPath.includes(ROUTE_OPEN_HISTORY_APPLICATION_FOR_USER)) {
    const newUrl = to.fullPath.replace(ROUTE_PAGE_APPLICATION_FOR_USER, ROUTE_PAGE_APPLICATION_FOR_MANAGER)
    next(newUrl)
  }

  if (canAdmin && canUser) {
    isAdmin || isUser ? next() : next('/')
    return;
  }

  if (canAdminCompetition && isAdminCompetiton) {
    next()
    return;
  }

  if (canExpert && isExpert) {
    next()
    return;
  }

  if (canAdmin) {
    isAdmin ? next() : next('/')
    return;
  }

  if (canManager && canUser) {
    isManager || isUser ? next() : next('/')
    return;
  }

  if (canManager) {
    isManager ? next() : next('/')
    return;
  }

  if (canUser) {
    isUser ? next() : next('/')
    return;
  }

  next()
});

export default router
