<template>
  <the-container>
    <v-row
      v-if="isLoading"
      justify="center"
      class="mb-6"
    >
      <v-col cols="auto">
        <v-progress-circular
          color="primary"
          :indeterminate="true"
        />
      </v-col>
    </v-row>
    <service-panels
      v-if="isManager || isAdminCompetition || isAdmin || isExpert"
      :items="items"
    />
    <v-row v-else>
      <v-col
        v-for="item in items"
        :key="item.id"
        lg="4"
        md="6"
        cols="12"
      >
        <ServiceItem :item="item" />
      </v-col>
    </v-row>
    <v-row
      justify="center"
      class="mt-1"
    >
      <v-col cols="auto">
        <v-btn
          v-if="hasLoadMore"
          variant="text"
          class="text-body-1"
          @click.prevent="loadMore"
        >
          Загрузить еще
        </v-btn>
      </v-col>
    </v-row>
  </the-container>
</template>

<script setup>
import { api } from "@/api/Api";
import { computed, onMounted, ref } from "vue";
import ServiceItem from "@/components/Service/ServiceItem";
import TheContainer from "@/components/TheContainer";
import ServicePanels from "@/components/Service/ServicePanels";
import { useStore } from "vuex";
import {useRoute} from "vue-router/dist/vue-router";
import {SERVISE_TYPES} from "@/utils/constants";

const store = useStore()
const route = useRoute()

const items = ref([])
const currentPage = ref(1)
const pageCount = ref(0)
const isLoading = ref(false)

const hasLoadMore = computed(() => {
  return currentPage.value !== pageCount.value && items.value?.length
})
const isManager = computed(() => store.getters["user/isManager"])
const isExpert = computed(() => store.getters["user/isExpert"])
const isUser = computed(() => store.getters["user/isUser"])
const isAdmin = computed(() => store.getters["user/isAdmin"])
const isAdminCompetition = computed(() => store.getters["user/isAdminCompetition"])
async function loadItems(page) {
  isLoading.value = true
  let type

  if (isUser.value) {
    type = route.meta.isSelections ? SERVISE_TYPES.SELECTIONS.id : SERVISE_TYPES.SERVISE.id
  }

  if (isAdminCompetition.value) {
    type = null
  }


  const {
    items: contests,
    _meta: {
      currentPage: currentPageContests,
      pageCount: pageCountContests,
    }
  } = await api.contest().getContests(page, undefined, type).finally(() => isLoading.value = false)
  currentPage.value = currentPageContests
  pageCount.value = pageCountContests
  items.value = [items.value, contests]
    .flat()
    .filter(item => Object.values(item).length !== 0)
}

function loadMore () {
  currentPage.value += 1
  loadItems(currentPage.value)
}

onMounted(() => {
  loadItems(currentPage.value)
})

</script>

<style lang="scss" scoped>
.container {
  margin: 0 auto;
  position: relative;
  max-width: 1170px;
  padding-top: 64px;
}
</style>

