<template>
  <v-dialog v-model="dialog">
    <v-card width="500">
      <v-card-text>
        <h3 class="mb-6">
          Обратная связь
        </h3>
        <v-row
          v-if="loading"
          class="justify-center align-center py-6"
        >
          <v-progress-circular
            color="primary"
            :indeterminate="true"
          />
        </v-row>
        <v-form
          v-else
          ref="form"
          v-model="validForm"
        >
          <div
            v-for="field in fieldsForm"
            :key="field.id"
          >
            <p
              v-if="field.title"
              class="text-body-1 ma-0"
            >
              {{ field.title }}
            </p>
            <Component
              :is="inputs[getFormInput(field.type)]"
              v-bind="field"
              @update-value="updateValue"
            />
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          variant="text"
          @click="submitForm"
        >
          Отправить
        </v-btn>
        <v-btn
          color="primary"
          variant="text"
          @click="dialogToggle"
        >
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { api } from "@/api/Api";
import { v4 as uuidv4 } from "uuid";
import FormText from "@/components/Form/FormText";
import FormTextarea from "@/components/Form/FormTextarea";
import FormSelect from "@/components/Form/FormSelect";
import FormFile from "@/components/Form/FormFile";
import { useToast } from "@/composables/useToast";
import { DEFAULT_ID_PROJECT } from "@/constants";

const inputs = {
  FormText,
  FormTextarea,
  FormSelect,
  FormFile,
};

const dialog = ref(false);
const categories = ref([]);
const validForm = ref(true);
const form = ref(null);
const loading = ref(false);
const { toast } = useToast();

const fieldsForm = ref([
  {
    id: uuidv4(),
    group_id: 0,
    type: "select",
    name: "theme",
    title: "Категория обращения",
    options: [],
    default_value: "",
    descr: "",
    note: "",
    multiple: 0,
    rules: [["required"]],
    sort: 1,
    itemValue: "id",
    itemTitle: "name",
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: "text",
    name: "contestName",
    title: "Наименование конкурса/отбора",
    default_value: "",
    descr: "",
    note: "",
    multiple: 0,
    rules: [],
    sort: 40,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: "text",
    name: "orderNumber",
    title: "Номер заявки",
    default_value: "",
    descr: "",
    note: "",
    multiple: 0,
    rules: [],
    sort: 50,
  },
  {
    id: uuidv4(),
    group_id: 0,
    type: "text",
    name: "questionnaireNumber",
    title: "Номер анкеты",
    default_value: "",
    descr: "",
    note: "",
    multiple: 0,
    rules: [],
    sort: 60,
  },

  {
    isVisible: true,
    id: uuidv4(),
    group_id: 0,
    type: "text",
    name: "subject",
    title: "Тема обращения",
    default_value: "",
    descr: "",
    note: "",
    multiple: 0,
    rules: [["required"], ["string", { max: "255" }]],
    sort: 20,
  },
  {
    isVisible: true,
    id: uuidv4(),
    group_id: 0,
    type: "textarea",
    name: "message",
    title: "Текст обращения",
    options: [],
    default_value: "",
    descr: "",
    note: "",
    multiple: 0,
    rules: [["required"]],
    sort: 30,
  },
  {
    id: 5,
    group_id: 0,
    type: "file",
    name: "attachments",
    title: "Вложения",
    options: [],
    default_value: [],
    descr: "",
    note: "",
    multiple: true,
    rules: [["required"]],
    sort: 500,
  },
]);

function dialogToggle() {
  dialog.value = !dialog.value;
}

function getFormInput(type) {
  switch (type) {
    case "text": {
      return "FormText";
    }
    case "textarea": {
      return "FormTextarea";
    }
    case "select": {
      return "FormSelect";
    }
    case "file": {
      return "FormFile";
    }
    default: {
      return "";
    }
  }
}

function focusErrorInput({ id }) {
  const inputId = "#" + id;
  document.querySelector(inputId).focus();
}

async function submitForm () {
  await form.value.validate()

  if (!validForm.value) {
    const errInput = form.value.errors[0]
    focusErrorInput(errInput)
    return
  }
  loading.value = true

  const formData = new FormData()
  formData.append('project', DEFAULT_ID_PROJECT)
  fieldsForm.value.forEach((el) => {
    if (el.name !== 'attachments') {
      formData.append(el.name, el.default_value)
    } else {
      el.default_value.forEach((el) => {
        formData.append('attachments[]', el)
      })
    }
  })

  try {
    await api.feedback().sendFeedbackForm(formData)
    dialogToggle()
    toast.success('Сообщение отправлено')
    fieldsForm.value.forEach((el) => {
      el.default_value = null
    })
  } catch (err) {
    toast.error(err.message)
  } finally {
    loading.value = false
  }
}


function updateValue({ value, id }) {
  fieldsForm.value.forEach((item) => {
    if (item.id === id) {
      item.default_value = value;
    }
  });
}

async function getCategories() {
  loading.value = true;

  await api
    .feedback()
    .getListCategoriesProject(DEFAULT_ID_PROJECT)
    .then((data) => {
      categories.value = data?.items;
      fieldsForm.value.find((field) => field.name === "theme").options =
        data?.items;
    })
    .finally(() => {
      loading.value = false;
    });
}

defineExpose({
  dialogToggle,
});

onMounted(() => {
  getCategories();
});
</script>
