<template>
  <v-dialog
    v-model="dialog"
  >
    <v-card>
      <v-card-text>
        <v-form>
          <v-col>
            <v-row
              v-if="!contestMonitors.length"
              justify="center"
            >
              <v-col cols="auto">
                <span class="text-caption">На конкурс не назначен монитор</span>
              </v-col>
            </v-row>
            <v-row
              v-for="monitor in contestMonitors"
              :key="monitor?.id"
              align="center"
            >
              <v-col>
                <span class="text-body-1"> {{ monitor?.fio }}</span>
              </v-col>
              <v-col cols="auto">
                <v-btn
                  :loading="isLoadingDelete"
                  variant="text"
                  color="red"
                  class="text-caption"
                  @click="() => deleteMonitor(monitor.id)"
                >
                  Удалить монитора
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              v-if="!!filteredAllMonitors.length"
              class="mx-0"
            >
              <v-combobox
                id="managersList"
                v-model="selectedAddMonitors"
                chips
                name="managers"
                :items="filteredAllMonitors"
                item-title="fio"
                item-value="id"
                hide-details="auto"
                :label="selectedAddMonitors ? '' : 'Выберите монитора'"
                variant="underlined"
              />
            </v-row>
            <v-row
              v-if="!!filteredAllMonitors.length"
              justify="center"
              class="pt-6"
            >
              <v-btn
                :disabled="!selectedAddMonitors"
                :loading="isLoading"
                color="primary"
                variant="text"
                size="small"
                @click.prevent="setMonitors"
              >
                Добавить монитора
              </v-btn>
            </v-row>
          </v-col>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row justify="center">
          <v-col cols="auto">
            <v-btn
              v-if="!isLoading"
              color="primary"
              variant="text"
              @click.prevent="close"
            >
              Закрыть
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";

import { api } from "@/api/Api";
import { MONITOR } from "@/enums/roles";
import { useToast } from "@/composables/useToast";
const { toast } = useToast()

const props = defineProps({
  contestId: {
    type: Number,
    default: null
  },
  contestMonitors: {
    type: Array,
    default: () => []
  }
})

const dialog = ref(false)
const allMonitors = ref([])
const selectedAddMonitors = ref()
const isLoading = ref(false)
const isLoadingDelete = ref(false)
const currentPage = ref(1)
const pageCount = ref()

const filteredAllMonitors = computed(() => {
  return  allMonitors.value.filter(monitor => {
    const hasInArray = props.contestMonitors.find(contestMonitor => contestMonitor?.id === monitor?.id)
    if (!hasInArray) {
      return monitor
    }
  })
})

function open () {
  dialog.value = true
}

function close () {
  selectedAddMonitors.value = null
  dialog.value = false
}

async function getAllMonitors () {
  const { items, _meta } = await api.user().getUsersByGroup(MONITOR, currentPage.value)

  if (currentPage.value === 1) {
    allMonitors.value = items
  } else {
    allMonitors.value = [...allMonitors.value, ...items]
  }

  if (_meta.pageCount > currentPage.value) {
    currentPage.value = ++currentPage.value
    pageCount.value = _meta.pageCount

    await getAllMonitors()
  }
}

async function setMonitors () {
  isLoading.value = true

  try {
    const form = new FormData()
    form.append('contest_id', props.contestId)
    form.append('user_id', selectedAddMonitors.value?.id)
    await api.contest().setMonitorToContest(form).then(() => {
      toast.success('Монитор успешно добавлен')
    })
    close()
    selectedAddMonitors.value = null
  } catch (e) {
    toast.error('Произошла ошибка при добавлении')
  } finally {
    isLoading.value = false
  }

}

async function deleteMonitor (userId) {
  const form = new FormData()
  form.append('contest_id', props.contestId)
  form.append('user_id', userId)
  isLoadingDelete.value = true
  try {
    await api.contest().deleteMonitorToContest(form)
    toast.success('Монитор успешно удален')
    close()
  } catch (e) {
    toast.error('Произошла ошибка при удалении')
  } finally {
    isLoadingDelete.value = false
  }

}

onMounted(() => {
  getAllMonitors()
})

defineExpose({
  open
})
</script>
