import { useYandexMetrika } from 'yandex-metrika-vue3'

export function sendYandexMetrics(goal) {
    const yandexMetric = useYandexMetrika()

    yandexMetric.reachGoal(goal)
}

export function sendTopMailRuMetrics(goal) {
    let _tmr = window._tmr || (window._tmr = [])
    _tmr.push({ type: 'reachGoal', id: process.env.VUE_APP_TOP_MAIL_RU_METRIC, goal: goal})
}
