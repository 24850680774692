import {SERVISE_TYPES} from "@/utils/constants"

export const basisContestFields = [
  {
    id: 0,
    group_id: 0,
    type: "radio",
    name: "type",
    title: "Тип",
    options: [
      {
        id: SERVISE_TYPES.SERVISE.id,
        name: SERVISE_TYPES.SERVISE.name,
        value: SERVISE_TYPES.SERVISE.id,
        description: "",
        disabled: "N",
        sort: 10
      },
      {
        id: SERVISE_TYPES.SELECTIONS.id,
        name:  SERVISE_TYPES.SELECTIONS.name,
        value: SERVISE_TYPES.SELECTIONS.id,
        description: "",
        disabled: "N",
        sort: 10
      }
    ],
    default_value: 0,
    descr: "",
    note: "",
    multiple: 0,
    rules: null,
    sort: 100
  },
  {
    id: 1,
    group_id: 0,
    type: "radio",
    name: "active",
    title: "Активность",
    options: [
      {
        id: 7,
        name: "Да",
        value: 1,
        description: "",
        disabled: "N",
        sort: 10
      },
      {
        id: 8,
        name: "Нет",
        value: 0,
        description: "",
        disabled: "N",
        sort: 10
      }
    ],
    default_value: 0,
    descr: "",
    note: "",
    multiple: 0,
    rules: null,
    sort: 100
  },
  {
    id: 2,
    group_id: 0,
    type: "text",
    name: "name",
    title: "Полное название конкурса",
    default_value: "",
    descr: "",
    note: "",
    multiple: 0,
    rules: [["required"], ["string", {max: "255"}]],
    sort: 200
  },
  {
    id: 3,
    group_id: 0,
    type: "text",
    name: "short_name",
    title: "Короткое название конкурса",
    default_value: "",
    descr: "Используется для вывода в тексте и заголовках писем, служебных сообщениях.",
    note: "",
    multiple: 0,
    rules: [["string", {max: "255"}]],
    sort: 300
  },
  {
    id: 4,
    group_id: 0,
    type: "textarea",
    name: "preview",
    title: "Текст короткого описания",
    options: [],
    default_value: "",
    descr: "Выводится на витрине конкурсов.",
    note: "",
    multiple: 0,
    rules: [["required"]],
    sort: 400
  },
  {
    id: 5,
    group_id: 0,
    type: "file",
    name: "logo",
    title: "Логотип конкурса",
    options: [],
    default_value: [],
    descr: "",
    note: "",
    multiple: 0,
    rules: [["required"], ["file", {extensions: ["png", "jpg", "svg"]}]],
    sort: 500,
  },
  {
    id: 6,
    group_id: 0,
    type: "text",
    name: "description_link",
    title: "Ссылка для кнопки «Подробно»",
    options: [],
    default_value: "",
    descr: "Заменяет собой страницу подробной информации о конкурсе",
    note: "",
    multiple: 0,
    rules: null,
    sort: 600
  },
  {
    id: 7,
    group_id: 0,
    type: "textarea",
    name: "description",
    title: "Описание конкурса",
    options: [],
    default_value: "",
    descr: "",
    note: "",
    multiple: 0,
    rules: [],
    sort: 700
  },
  {
    id: 8,
    group_id: 0,
    type: "textarea",
    name: "requirements",
    title: "Требования к участникам",
    options: [],
    default_value: "",
    descr: "",
    note: "",
    multiple: 0,
    rules: [],
    sort: 800
  },
  {
    id: 9,
    group_id: 0,
    type: "textarea",
    name: "who_suits",
    title: "На кого ориентирован",
    options: [],
    default_value: "",
    descr: "",
    note: "",
    multiple: 0,
    rules: [],
    sort: 900
  },
  {
    id: 10,
    group_id: 0,
    type: "radio",
    name: "new",
    title: "Новый конкурс",
    options: [
      {
        id: 7,
        name: "Да",
        value: 1,
        description: "",
        disabled: "N",
        sort: 10
      },
      {
        id: 8,
        name: "Нет",
        value: 0,
        description: "",
        disabled: "N",
        sort: 10
      }
    ],
    default_value: 0,
    descr: "",
    note: "",
    multiple: 0,
    rules: null,
    sort: 1000
  },
  {
    id: 11,
    group_id: 0,
    type: "text",
    name: "more_link",
    title: "Ссылка на сайт конкурса",
    options: [],
    default_value: "",
    descr: "",
    note: "",
    multiple: 0,
    rules: null,
    sort: 1100
  },
  {
    id: 12,
    group_id: 0,
    type: "text",
    name: "sort",
    title: "Индекс сортировки",
    options: [],
    default_value: "",
    descr: "Используется для выстраивания порядка конкурсов (чем меньше, тем выше).",
    note: "",
    multiple: 0,
    rules: null,
    sort: 1200
  },
  {
    id: 13,
    group_id: 0,
    type: "select",
    name: "parent_id",
    title: "ID родительского конкурса",
    options: [],
    default_value: 0,
    descr: "",
    note: "",
    multiple: 0,
    rules: [["required"]],
    sort: 1300,
    itemValue: 'id'
  },
  {
    id: 14,
    group_id: 0,
    type: "text",
    name: "email",
    title: "E-mail для кнопки «Связаться с менеджером»",
    options: [],
    default_value: "",
    descr: "Так же используется как отправитель при отправке писем",
    note: "",
    multiple: 0,
    rules: [["required"], ["email"]],
    sort: 1400
  },
  {
    id: 15,
    group_id: 0,
    type: "textarea",
    name: "email_sign",
    title: "Подпись отправителя в письмах участникам",
    options: [],
    default_value: "",
    descr: "",
    note: "",
    multiple: 0,
    rules: [["required"]],
    sort: 1500
  },
  {
    id: 16,
    group_id: 0,
    type: "textarea",
    name: "success_message",
    title: "Выводится при успешной отправке анкеты на экране и отправляется по почте пользователю.",
    options: [],
    default_value: "",
    descr: "",
    note: "",
    multiple: 0,
    rules: [["required"]],
    sort: 1600
  },
  {
    id: 17,
    group_id: 0,
    type: "group",
    name: "support",
    title: "Меры поддержки",
    options: [],
    default_value: "",
    descr: "",
    note: "",
    multiple: 1,
    rules: "",
    sort: 1700,
    buttonAdd: "Добавить меру поддержки"
  },
  {
    id: 171,
    group_id: 17,
    type: "text",
    name: "support_title[]",
    name_children: "title",
    title: "Заголовок",
    options: [],
    default_value: "",
    descr: "",
    note: "",
    multiple: 0,
    rules: null,
    sort: 1710,
    isNotSendEmptyData: true
  },
  {
    id: 172,
    group_id: 17,
    type: "text",
    name: "support_description[]",
    name_children: "description",
    title: "Краткое описание",
    options: [],
    default_value: "",
    descr: "",
    note: "",
    multiple: 0,
    rules: null,
    sort: 1720,
    isNotSendEmptyData: true
  },
  {
    id: 173,
    group_id: 17,
    type: "text",
    name: "support_sort[]",
    name_children: "sort",
    title: "Индекс сортировки",
    options: [],
    default_value: "",
    descr: "",
    note: "",
    multiple: 0,
    rules: null,
    sort: 1730,
    isNotSendEmptyData: true
  },
  {
    id: 18,
    group_id: 0,
    type: "group",
    name: "stages",
    title: "Этап конкурса",
    options: [],
    default_value: "",
    descr: "",
    note: "",
    multiple: 1,
    rules: "",
    sort: 1800,
    buttonAdd: "Добавить этап"
  },
  {
    id: 181,
    group_id: 18,
    type: "text",
    name: "stage_title[]",
    name_children: "title",
    title: "Заголовок",
    options: [],
    default_value: "",
    descr: "",
    note: "",
    multiple: 0,
    rules: null,
    sort: 1810,
    isNotSendEmptyData: true
  },
  {
    id: 182,
    group_id: 18,
    type: "text",
    name: "stage_description[]",
    name_children: "description",
    title: "Краткое описание",
    options: [],
    default_value: "",
    descr: "",
    note: "",
    multiple: 0,
    rules: null,
    sort: 1820,
    isNotSendEmptyData: true
  },
  {
    id: 183,
    group_id: 18,
    type: "text",
    name: "stage_sort[]",
    name_children: "sort",
    title: "Индекс сортировки",
    options: [],
    default_value: "",
    descr: "",
    note: "",
    multiple: 0,
    rules: null,
    sort: 1830,
    isNotSendEmptyData: true
  },
  {
    id: 19,
    group_id: 0,
    type: "group",
    name: "status",
    title: "Статус конкурса",
    options: [],
    default_value: "",
    descr: "",
    note: "",
    multiple: 1,
    rules: [["required"]],
    sort: 1900,
    buttonAdd: "Добавить статус"
  },
  {
    id: 191,
    group_id: 19,
    type: "text",
    name: "status_name[]",
    name_children: "name",
    title: "Название",
    options: [],
    default_value: "",
    descr: "",
    note: "",
    multiple: 0,
    rules: [["required"], ["string", {max: "255"}]],
    sort: 1910
  },
  {
    id: 192,
    group_id: 19,
    type: "radio",
    name: "status_end[]",
    name_children: "end",
    title: "Блокирующий статус",
    options: [
      {
        id: 7,
        name: "Да",
        value: "Y",
        description: "",
        disabled: "N",
        sort: 10
      },
      {
        id: 8,
        name: "Нет",
        value: "N",
        description: "",
        disabled: "N",
        sort: 10
      }
    ],
    default_value: "N",
    descr: "",
    note: "",
    multiple: 0,
    rules: null,
    sort: 1920
  },
  {
    id: 193,
    group_id: 19,
    type: "text",
    name: "status_sort[]",
    name_children: "sort",
    title: "Индекс сортировки",
    options: [],
    default_value: "",
    descr: "",
    note: "",
    multiple: 0,
    rules: [["required"]],
    sort: 1930
  },
  {
    id: 194,
    group_id: 19,
    type: "radio",
    name: "status_winner[]",
    name_children: "winner",
    title: "Статус победитель",
    options: [
      {
        id: 7,
        name: "Да",
        value: "Y",
        description: "",
        disabled: "N",
        sort: 10
      },
      {
        id: 8,
        name: "Нет",
        value: "N",
        description: "",
        disabled: "N",
        sort: 10
      }
    ],
    default_value: "N",
    descr: "",
    note: "",
    multiple: 0,
    rules: null,
    sort: 1921
  },
  {
    id: 200,
    group_id: 0,
    type: "text",
    name: "metrika_goal",
    title: "Цель метрики",
    options: [],
    default_value: "",
    descr: "",
    note: "",
    multiple: 0,
    rules: [],
    sort: 1930
  },
  {
    id: 201,
    group_id: 0,
    type: "file",
    name: "file_agreement",
    title: "Соглашение о передаче персональных данных",
    options: [],
    default_value: [],
    descr: "",
    note: "",
    multiple: 0,
    rules: [["required"], ["file", {extensions: ["pdf"]}]],
    sort: 500,
  },
]
