<template>
  <v-dialog
    v-model="showDialog"
    persistent
  >
    <v-card
      class="px-4 pt-4"
    >
      <FormMain
        ref="formRef"
        :data="fieldsForm"
        is-dialog
        @get-forms="updateForm"
      />
      <v-row
        justify="center"
        class="row--sticky"
      >
        <v-col cols="auto">
          <v-btn
            color="primary"
            variant="text"
            @click="getForm"
          >
            {{ successButton.title }}
          </v-btn>
          <v-btn
            color="primary"
            variant="text"
            @click="closeDialog"
          >
            {{ closeButton.title }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script setup>
import {ref} from "vue";
import FormMain from "@/components/Form/FormMain";

const emit = defineEmits(['action-form'])

const props = defineProps({
  fieldsForm: {
    type: Array,
    default: () => []
  },
  successButton: {
    type: Object,
    default: () => {
      return {
        title: "Добавить"
      }
    }
  },
  closeButton: {
    type: Object,
    default: () => {
      return {
        title: "Отменить"
      }
    }
  },
  action: {
    type: String,
    default: ""
  }
})

const showDialog = ref(false)
const formRef = ref(null)

function openDialog () {
  showDialog.value = true
}
function closeDialog () {
  showDialog.value = false
}
function getForm () {
  if (formRef.value) {
    formRef.value.getForm()
  }
}
async function updateForm (inputs, valid) {
  emit('action-form', inputs, valid, props.action)
}


defineExpose({
  openDialog,
  closeDialog
})

</script>
<script>
export default {
  name: "FormMainDialog"
}
</script>

<style lang="scss" scoped>
.row {
  &--sticky {
    position: sticky;
    bottom: 0;
    background-color: white;
  }
}
</style>
