import { axiosCustom } from '@/plugins/axios';

export class Result {
  getResults({
    page = 1,
    status,
    pageSize = 20,
    formId,
    userId,
    date_from,
    date_to,
    q,
  }) {
    const data = {
      page,
      ...(status && { status }),
      pageSize,
      ...(formId && { form_id: formId }),
      ...(userId && { user_id: userId }),
      ...(date_from && { date_from }),
      ...(date_to && { date_to }),
      ...(q && { q }),
    };

    const params = new URLSearchParams(data).toString();

    return axiosCustom
      .get(`/api/result?${params}`)
      .then((response) => response.data);
  }

  getOrders(page = 1, pageSize = 20) {
    let data = {
      page,
      pageSize,
    };
    const params = new URLSearchParams(data).toString();
    return axiosCustom
      .get(`/api/application?${params}`)
      .then((response) => response.data);
  }

  getOrder(idOrder) {
    return axiosCustom
      .get(`/api/application/${idOrder}`)
      .then((response) => response.data);
  }

  getResultById(id) {
    return axiosCustom
      .get(`/api/result/${id}`)
      .then((response) => response.data);
  }

  sendResultByForm(form) {
    const headers = { 'Content-Type': 'multipart/form-data' };
    return axiosCustom
      .post('/api/result', form, { headers })
      .then((response) => response.data);
  }

  updateResult(id, form) {
    return axiosCustom
      .put(`/api/result/${id}`, form)
      .then((response) => response.data);
  }

  setStatus(id, status, reason) {
    const params = {};
    params.id = id;
    params.status = status;
    if (reason) {
      params.reason = reason;
    } else {
      params.reason = '';
    }
    const queryString = new URLSearchParams(params).toString();
    return axiosCustom
      .get(`/api/result/setstatus?${queryString}`)
      .then((response) => response.data);
  }

  exportResultByFormId(form) {
    const params = new URLSearchParams(form).toString();
    return axiosCustom
      .get(`/api/result/export?${params}`, { responseType: 'blob' })
      .then((response) => response.data);
  }

  getActiveResultsByFormIdAndUserId(formId, userId) {
    const params = new URLSearchParams({
      form_id: formId,
      user_id: userId,
    }).toString();
    return axiosCustom
      .get(`/api/result?${params}`)
      .then((response) => response.data);
  }
}
