<template>
  <v-select
    :id="`inputCustom${id}`"
    ref="select"
    :model-value="inputModel"
    :items="options"
    :item-title="itemTitle"
    :item-value="itemValue"
    :multiple="Boolean(multiple)"
    :hint="descr"
    :persistent-hint="Boolean(descr)"
    :rules="rulesCommon"
    variant="outlined"
  >
    <template #item="{item: {raw}}">
      <v-list-item
        :disabled="formatPrimitiveChar(raw.disabled)"
        class="select__name"
        @click="setNewValue(raw[itemValue])"
      >
        {{ raw[itemTitle] }}
        <v-tooltip
          v-if="raw.description"
          activator="parent"
          location="top"
        >
          <div v-html="raw.description" />
        </v-tooltip>
      </v-list-item>
    </template>
  </v-select>
</template>

<script setup>
import { props as baseProps, emit as baseEmit } from "@/composables/form/usePropsForm";
import { useForm } from "@/composables/form/useForm";
import {ref} from "vue";
import {formatPrimitiveChar} from "@/utils/common";

const props = defineProps({
  ...baseProps,
  itemValue: {
    type: String,
    default: 'value',
  },
  itemTitle: {
    type: String,
    default: 'name',
  }
});
const emit = defineEmits(baseEmit)

const select = ref(null)

function setNewValue(value){
  inputModel.value = value
  select.value.menu = false
}

const { rulesCommon, inputModel } = useForm(props, emit);
</script>

<style lang="scss" scoped>
.select{
  &__name{
    cursor: pointer;
    &:hover{
      background-color: rgba(224, 222, 222, 0.4);
      opacity: 0.9;
    }
  }
}
</style>
