import { createApp } from 'vue';
import Maska from 'maska';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import PrimeVue from 'primevue/config';
import 'maz-ui/css/main.css';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import '@/styles/main.scss';
import moment from 'moment';

const app = createApp(App);

// const yandexMetrikaOptions = {
//     defer: true,
//     clickMap: true,
//     trackLinks: true,
//     accurateTrackBounce: true
// }

app.use(router);
app.use(store);
app.use(vuetify);
app.use(PrimeVue);
app.use(Maska);
app.use(moment);
// app.use(initYandexMetrika, {
    //         id: process.env.VUE_APP_YANDEX_TRACKING_ID,
    //         router: router,
    //         env: process.env.NODE_ENV,
    //         options: yandexMetrikaOptions,
    //     })

app.mount('#app');