import axios from "axios";
import {tokenService} from "@/services/token";
import store from "@/store";
import router from "@/router";

const axiosCustom = axios.create({
  baseURL: '/',
});

const axiosSupport = axios.create({
  baseURL: process.env.VUE_APP_SUPPORT_BASE_URL,
});

const axiosNotifications = axios.create({
  baseURL: process.env.VUE_APP_NOTIFICATION_BASE_URL,
})

/** Auth token interceptors */
const authInterceptor = config => {
  config.headers.Authorization = `Bearer ${tokenService.getToken()}`;
  return config;
};

/** Adding the request interceptors */
axiosCustom.interceptors.request.use(authInterceptor);

axiosSupport.interceptors.request.use(authInterceptor);

axiosNotifications.interceptors.request.use(authInterceptor);

axiosCustom.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      const { status } = error.response;

      if (status === 401) {
        store.dispatch('auth/logout').then(() => router.push('/login'));
      }
    }

    return Promise.reject(error);
  }
);

axiosSupport.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      const { response: { status } } = error
      if (status === 401){
        store.dispatch('auth/logout').then()
        router.push('/login').then()
      }

      return Promise.reject(error);
    }
);

axiosNotifications.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      const { response: { status } } = error
      if (status === 401){
        store.dispatch('auth/logout').then()
        router.push('/login').then()
      }

      return Promise.reject(error);
    }
);

export {
  axiosCustom,
  axiosSupport,
  axiosNotifications
}
