<template>
  <auth-layout>
    <div class="form">
      <div class="form__body">
        <p class="text-body-1 text-grey text-center">
          Для продолжения требуется <br> авторизоваться с помощью
          <SvgBase
            width="80"
            height="15"
            view-box="0 0 135 20"
          >
            <SvgLogoLeaderId />
          </SvgBase>
        </p>

        <v-btn
          :block="true"
          color="primary"
          @click="login"
        >
          Войти
        </v-btn>
      </div>
    </div>
  </auth-layout>
</template>

<script setup>

import {api} from "@/api/Api";
import AuthLayout from "@/layouts/auth";
import SvgBase from "@/components/Svg/SvgBase";
import SvgLogoLeaderId from "@/components/Svg/SvgLogoLeaderId";

function login() {
  const uri = api.auth().authorize()
  window.open(uri , '_self')
}
</script>

<style lang="scss" scoped>
.form {
  width: 498px;
  display: block;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;

  &__body {
    display: flex;
    padding: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
    align-items: center;
    flex-direction: column;
    @media (max-width: 599.95px) {
      padding: 24px 16px 45px;
    }
  }

  @media (max-width: 599.95px) {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }

}
</style>

