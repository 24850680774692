import { tokenService } from "@/services/token";
import { api } from "@/api/Api";

const state = {
  isLoggedIn: Boolean(tokenService.getToken()),
  accessToken: tokenService.getToken(),
  entryPath: localStorage.getItem("entryPath"),
};

const getters = {
  isLoggedIn(state) {
    return state.isLoggedIn;
  },
  accessToken(state) {
    return state.accessToken;
  },
  entryPath(state) {
    return state.entryPath;
  },
};

const actions = {
  async login(_ctx, code) {
    try {
      const data = await api.auth().getAccessToken(code);
      const {
        access_token: accessToken,
        access_token_expires: accessTokenExpires,
      } = data;

      if (process.env.NODE_ENV === "production") {
        // Import metrics only in production environment
        const { sendTopMailRuMetrics, sendYandexMetrics } = await import(
          "@/metrics"
        );
        const goal = "lead";
        sendYandexMetrics(goal);
        sendTopMailRuMetrics(goal);
      }

      await tokenService.saveToken(accessToken);
      await tokenService.saveAccessTokenExpires(accessTokenExpires);
      const redirectRoute = localStorage.getItem("redirectRoute");
      const redirectLK2Route = localStorage.getItem(
        process.env.VUE_APP_REDIRECT_KEY_LK
      );

      if (redirectLK2Route) {
        window.location.href = `${process.env.VUE_APP_LK2_ROUTE}${process.env.VUE_APP_REDIRECT_KEY_LK}?token=${accessToken}`;
        localStorage.removeItem(process.env.VUE_APP_REDIRECT_KEY_LK);
        return;
      } else {
        document.location.replace(redirectRoute);
      }

      return {
        success: true,
      };
    } catch (e) {
      console.log(e);
      return {
        success: false,
        errors: e?.response?.data?.errors,
      };
    }
  },
  logout() {
    tokenService.removeToken();
    tokenService.removeAccessTokenExpires();
  },
};

const mutations = {};

export const auth = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
