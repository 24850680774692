<template>
  <div class="support__item">
    <svg
      class="support__svg"
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
    >
      <path
        d="M1 3.84211L4.44828 7L11 1"
        stroke="#1A2574"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
    <div class="text-body-1 d-flex flex-column">
      <template v-if="description">
        <strong class="support__strong">{{ title }}</strong>
        <span>{{ description }}</span>
      </template>
      <template v-else>
        <span>{{ title }}</span>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceSupportItem",
};
</script>

<script setup>
// eslint-disable-next-line no-undef
defineProps({
  title: {
    type: String,
    default: ''
  },
  description: {
    type: String,
    default: ''
  }
});

</script>

<style lang="scss" scoped>
.jss201 {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
}
.jss201 > svg {
  margin-top: 8px;
  flex-shrink: 0;
  margin-right: 16px;
}
.jss201:first-child {
  margin-top: 8px;
}
@media (max-width:599.95px) {
  .jss201 {
    margin-bottom: 12px;
  }
  .jss201:first-child {
    margin-top: 10px;
  }
  .jss201 > p {
    font-size: 12px;
    line-height: 18px;
  }
}


.support {
  &__item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
    &:last-child {
      margin-top: 8px;
    }
    @media (max-width:599.95px) {
        margin-bottom: 12px;
      &:first-child {
        margin-top: 10px;
      }
      & > p {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }

  &__svg {
    margin-top: 8px;
    flex-shrink: 0;
    margin-right: 16px;
  }

  &__strong{
    font-weight: 700;
    text-decoration: underline;
  }

  & > p {
    margin-top: 0;
    color: #000000;
  }

}
</style>