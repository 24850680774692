<template>
  <v-dialog
    v-model="dialog"
  >
    <v-card>
      <v-card-text>
        <v-form>
          <v-col>
            <v-row
              v-if="!contestExperts.length"
              justify="center"
            >
              <v-col cols="auto">
                <span class="text-caption">На конкурс не назначены эксперты</span>
              </v-col>
            </v-row>
            <v-row
              v-for="expert in contestExperts"
              :key="expert?.id"
              align="center"
            >
              <v-col>
                <span class="text-body-1"> {{ expert?.fio }}</span>
              </v-col>
              <v-col cols="auto">
                <v-btn
                  variant="text"
                  color="red"
                  class="text-caption"
                  @click="() => deleteExpert(expert.id)"
                >
                  Удалить эксперта
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              v-if="!!filteredAllExperts.length"
              class="mx-0"
            >
              <v-combobox
                id="expertsList"
                v-model="selectedAddExperts"
                chips
                name="experts"
                multiple
                :items="filteredAllExperts"
                item-title="fio"
                item-value="id"
                hide-details="auto"
                :label="selectedAddExperts ? '' : 'Выберите экспертов'"
                variant="underlined"
              />
            </v-row>
            <v-row
              v-if="!!filteredAllExperts.length"
              justify="center"
              class="pt-6"
            >
              <v-btn
                :disabled="!selectedAddExperts"
                :loading="isLoading"
                color="primary"
                variant="text"
                size="small"
                @click.prevent="setExperts"
              >
                Добавить эксперта
              </v-btn>
            </v-row>
          </v-col>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row justify="center">
          <v-col cols="auto">
            <v-btn
              v-if="!isLoading"
              color="primary"
              variant="text"
              @click.prevent="close"
            >
              Закрыть
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";

import { api } from "@/api/Api";
import {EXPERT} from "@/enums/roles";
import { useToast } from "@/composables/useToast";
const { toast } = useToast()

const props = defineProps({
  contestId: {
    type: Number,
    default: null
  },
  contestExperts: {
    type: Array,
    default: () => []
  }
})

const dialog = ref(false)
const allExperts = ref([])
const selectedAddExperts = ref()
const isLoading = ref(false)
const isLoadingDelete = ref(false)
const currentPage = ref(1)
const pageCount = ref()

const filteredAllExperts = computed(() => {
  return  allExperts.value.filter(expert => {
    const hasInArray = props.contestExperts.find(contestExpert => contestExpert?.id === expert?.id)
    if (!hasInArray) {
      return expert
    }
  })
})

function open () {
  dialog.value = true
}

function close () {
  selectedAddExperts.value = null
  dialog.value = false
}

async function getAllExperts () {
  const { items, _meta } = await api.user().getUsersByGroup(EXPERT,currentPage.value )

  if (currentPage.value === 1) {
    allExperts.value = items
  } else {
    allExperts.value = [...allExperts.value, ...items]
  }

  if (_meta.pageCount > currentPage.value) {
    currentPage.value = ++currentPage.value
    pageCount.value = _meta.pageCount

    await getAllExperts()
  }
}

async function setExperts () {
  isLoading.value = true

  try {
    selectedAddExperts.value?.forEach((el) => {
      const form = new FormData()
      form.append('contest_id', props.contestId)
      form.append('user_id', el?.id)
      api.contest().setExpertToContest(form).then(() => {

      })
    })

    toast.success('Эксперт успешно добавлен')

    close()
    selectedAddExperts.value = null
  } catch (e) {
    toast.error('Произошла ошибка при добавлении')
  } finally {
    isLoading.value = false
  }

}

async function deleteExpert (userId) {
  const form = new FormData()
  form.append('contest_id', props.contestId)
  form.append('user_id', userId)
  isLoadingDelete.value = true
  try {
    await api.contest().deleteExpertToContest(form)
    toast.success('Эксперт успешно удален')
    close()
  } catch (e) {
    toast.error('Произошла ошибка при удалении')
  } finally {
    isLoadingDelete.value = false
  }

}

onMounted(() => {
  getAllExperts()
})

defineExpose({
  open
})
</script>
