<template>
  <v-dialog v-model="isShowDialog">
    <v-card>
      <v-card-text>
        Установить заявке статус - "{{ selectedStatus.status }}"
      </v-card-text>
      <v-card-actions>
        <v-row
          :no-gutters="true"
          justify="center"
        >
          <v-col cols="auto">
            <v-btn
              @click="success"
            >
              Да
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              @click="closeDialog"
            >
              Нет
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import {ref} from "vue";

defineProps({
  selectedStatus: {
    type: Object,
    required: true,
  }
})

const emit = defineEmits(['on-set-status'])

const isShowDialog = ref(false)

function openDialog () {
  isShowDialog.value = true
}
function closeDialog () {
  isShowDialog.value = false
}
function success () {
  emit('on-set-status')
  closeDialog()
}

defineExpose({
  openDialog
})

</script>
<script>
export default {
  name: "OrdersConfirmStatus",
}
</script>
