<template>
  <v-dialog
    v-model="dialog"
  >
    <v-card max-width="500">
      <v-card-text>
        <slot />
      </v-card-text>
      <v-card-actions>
        <v-row justify="center">
          <v-col cols="auto">
            <v-btn
              color="primary"
              class="mr-2"
              @click="confirm"
            >
              Отправить
            </v-btn>
            <v-btn
              color="primary"
              variant="text"
              @click.prevent="close"
            >
              Закрыть
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref } from "vue";

const emit = defineEmits(['confirm'])

const dialog = ref(false)

function open () {
  dialog.value = true
}

function close () {
  dialog.value = false
}

function confirm() {
  emit('confirm')
  close()
}

defineExpose({
  open
})
</script>
<script>
export default {
  name: "ConfirmDialog"
}
</script>
