<template>
  <v-textarea
    :id="`inputCustom${id}`"
    v-model="inputModel"
    :hint="descr"
    :persistent-hint="Boolean(descr)"
    :rules="rulesCommon"
    :maxlength="maxLength"
    :counter="maxLength"
    density="comfortable"
    variant="outlined"
  />
</template>

<script setup>
import { props as baseProps, emit as baseEmit } from "@/composables/form/usePropsForm";
import { useForm } from "@/composables/form/useForm";

const props = defineProps({
  ...baseProps
});
const emit = defineEmits(baseEmit)

const { maxLength, rulesCommon, inputModel } = useForm(props, emit);
</script>
