import {axiosCustom} from "@/plugins/axios";

export class Upload {
  getFile (url) {
    return axiosCustom
      .get(url, {responseType: 'blob'})
      .then((response) => response.data);
  }

}
